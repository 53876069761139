import React, { useState } from "react";
import Container from "../../components/container";
import { Step, Stepper } from 'react-form-stepper';
import Step1Content from "./Step1Content";
import Step2Content from "./Step2Content";
import Step3Content from "./Step3Content";
import Step4Content from "./Step4Content";
import { FaAngleLeft } from 'react-icons/fa';


const SubscriptionCancellation = () => {
  const [activeStep, setActiveStep] = useState(0);

  const headings = [
    "By cancelling the membership you will lose the following Benefits:",
    "Did you know that…",
    "We are sorry to see you go",
  ];

  const handleNext = () => {
    setActiveStep(prevStep => prevStep + 1);
  };
  const handlePrev = () => {
    setActiveStep(prevStep => prevStep - 1);
  };

  const getContentForStep = (step) => {
    switch (step) {
      case 0:
        return <Step1Content handleNext={handleNext} />;
      case 1:
        return <Step2Content handleNext={handleNext} />;
      case 2:
        return <Step3Content handleNext={handleNext}/>;
     case 3:
          // return <Step4Content handlePrev={handlePrev}/>;
      default:
        return null;
    }
  };

  return (
    <div className=" flex justify-center align-middle m-auto py-12 shadow-md xl:mt-8 lg:mt-6 mt-2" style={{background:"white",width:"80%",borderRadius:"25px"}}>
      <Container className=" box-shadow-sm" >
          
        {activeStep !== 0 && <div className="flex cursor-pointer mb-4" onClick={handlePrev}>
            <FaAngleLeft style={{fontSize: '20px'}}/> Back
            </div>}
        <h2 className="text-center font-bold">{headings[activeStep]}</h2>
        <Stepper activeStep={activeStep}>
          <Step label="Step 1"></Step>
          <Step label="Step 2"></Step>
          <Step label="Step 3"></Step>
          {/* <Step label="Step 4"></Step> */}
        </Stepper>
        <div>
          {getContentForStep(activeStep)}
        </div>
      </Container>
    </div>
  );
}

export default SubscriptionCancellation;
