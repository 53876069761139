import React, { useCallback, useEffect, useState } from "react";
import logo from "../../assets/images/simpleStudyingLogo.png";
import Logos from "../../assets/images/study_small.png";
import search_icon from "../../assets/images/search.webp";
import { Link, useHistory, withRouter } from "react-router-dom";
import "./index.css";
import { removeKey, setKey } from "../../helpers";
import Input from "../../components/Input/InputComponent";
import * as Yup from "yup";
import { GetUser } from "../../services";
import { Field, Form, Formik } from "formik";
import { Modal } from "react-responsive-modal";
import Container from "../container";
import Button from "../EmptyButton";
import img1 from "../../assets/newImages/Group 1399.png";
import img3 from "../../assets/newImages/Group 1403.png";
import img2 from "../../assets/newImages/Illustration.png";
import GoogleAuthentication from "../../components/SocialAuthentication/GoogleSocialAuth";
import FacebookAuthentication from "../../components/SocialAuthentication/FacebookSocialAuth";
import { useDispatch, useSelector } from "react-redux";
import {
  closeAlert,
  createErrorAlert,
  getSiteSearch,
  initialError,
} from "../../redux/actions";
import ErrorHandler from "../../components/Modal/ErrorHandler";
import Select from "../Select";
import { getApiKey, slugToText } from "../../helpers";

import ReactGA from "react-ga";
import AppConfig from "../../config/index";
import FullStory from "react-fullstory";
import apiRequest from "../../helpers/apiRequest";
import login from "../../services/Account/login";
import { BsBars } from "react-icons/bs";
import Burger from "../../assets/icons/burger.svg";
ReactGA.initialize(AppConfig.GOOGLE.GA_TRACKING_CODE);
const ORG_ID = "S1P4H";

const Logo = ({ showSearch, handleClick, source }) => {
  const history = useHistory();
  return !showSearch ? (
    <div onClick={() => history.push("/")}>
      <img
        src={source}
        alt="Logo"
        className="logoOne h-36 w-36"
        onClick={handleClick}
        style={{ cursor: "pointer" }}
      />
    </div>
  ) : null;
};

const Icon = ({ onClick }) => (
  <img
    src={search_icon}
    className="mr-5 img-fluid"
    alt="search_icon"
    width="23"
    onClick={onClick}
  />
);

const LoginButton = ({ signup, loginModals }) => {
  const history = useHistory();
  const [loginModal, setLoginModal] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const validateSchema = Yup.object({
    password: Yup.string().required("Password is required"),
    username: Yup.string().required("Username is required"),
  });

  useEffect(() => {
    if (loginModals === true) {
      setLoginModal(true);
    }
  }, [loginModals]);
  const userLogin = async (body) => {
    await apiRequest({
      method: "post",
      url: `accounts/dj-rest-auth/login/`,
      data: body,
    }).then((res) => {
      setKey(res.data.access_token);
      setLoginModal(false);
      if (getApiKey()) {
        window.location.reload();
      }
    });
  };
  const login = (values) => {
    userLogin(values);
  };
  const loginRoute = (location) => {
    if (location.pathname === "/") {
      history.push("/login");
    } else {
      setLoginModal(true);
    }
  };

  const forgetPass = () => {
    setLoginModal(false);
    history.push("/forget-password");
  };

  const handleClose = () => {
    setLoginModal(false);
  };

  return (
    <div className="inline-block mr-2 xl:w-36 text-center py-1 lg:py-2">
      <p
        onClick={() => {
          loginRoute(window.location);
        }}
        className="btn flex-auto border-2  text-13 sm:text-16 border-primary md:border-0 ml-1 text-primary py-1 lg:py-2 px-2 font-bold xl:w-full cursor-pointer"
      >
        Log in
      </p>
      <div className="parent-modal-class">
        <Modal
          open={loginModal}
          onClose={handleClose}
          focusTrapped={false}
          center
          classNames={{
            overlay: "custom-overlay-class",
            modal: "react-modal-class1", // Use your own custom class here
          }}
        >
          {/* <GoogleAuthentication />
            <FacebookAuthentication /> */}
          {/* <div className="flex flex-row justify-center items-center px-2 lg:px-6 my-2 lg:my-7">
          <div className="border border-ligthBorderGrey w-2/5"></div>
              <p className="px-3.5 text-darkest font-body-large ">or</p>
              <div className="border border-ligthBorderGrey w-2/5"></div>
        </div> */}
          <div className="flex gap-4">
            <div className="w-full hidden md:block">
              <p className="text-body-large  font-bold text-left md:text-h3 xl:text-36  mr-1">
                Join the movement of{" "}
                <span className="text-yellowish">26K savvy StudyCrowd.AI members</span>{" "}
                already empowered - and you're next
              </p>

              <p className=" text-body-small text-left pt-6 md:text-h5 xl:text-36 or-2">
                Discover the secret to success as you become part of our growing
                community.
              </p>
              <p className="text-body-small text-left pt-6 md:text-h5 xl:text-36 or-2"
              >
                Find simple and concise study resources created by top graduates who took the same courses as you at US and UK universities</p>
              <div className="flex justify-around mt-10 items-center flex-col lg:flex-row">
                {/* <div className=" text-center">
                  <div className="w-116 h-100">
                    <img src={img1} className="m-auto mt-3 w-full h-full" />
                  </div>
                  <p className="  fw-bold text-42 text-yellowish ">35+</p>
                  <p>
                    Study Modules
                    <br />
                    All main areas of English law
                  </p>
                </div> */}
                <div className=" text-center">
                  <div className="w-100 h-100">
                    <img src={img2} className="m-auto mt-3 w-full h-full" />
                  </div>
                  <p className="fw-bold text-42  text-yellowish">75K</p>
                  <p>
                    Active Users
                    <br />
                    in 2024
                  </p>
                </div>
                <div className=" text-center">
                  <div className="w-100 h-100">
                    <img src={img3} className="m-auto mt-3 w-full h-full" />
                  </div>
                  <p className="fw-bold text-42  text-yellowish">1600+</p>
                  <p>
                    Hours Tutoring <br />
                    Delivered
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full">
              <Formik
                initialValues={{
                  password: "",
                  username: "",
                }}
                validationSchema={validateSchema}
                onSubmit={(values) => login(values)}
              >
                {({ isValid, handleSubmit, values }) => (
                  <Form>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <div className="loginInputMain flex flex-col text-darkest items-center">
                        <p className="loginHeadText text-darkest font-bold text-body-large mb-10 md:text-h3 xl:text-36 text-center">
                          Sign in to{" "}
                          <span className="text-yellowish">StudyCrowd.AI</span>
                        </p>
                        <div className="w-full mb-3 custom2::mb-9 ">
                          <p className="mb-8">Username or Email</p>
                          <Field
                            name="username"
                            as={Input}
                            autoComplete="off"
                            placeholder="Enter your username or email"
                            type="text"
                          />
                        </div>
                        <div className="w-full  mb-3 custom2:mb-9">
                          <p className="mb-8 ">Password</p>
                          <Field
                            name="password"
                            as={Input}
                            placeholder="Enter your password"
                            type={showPassword ? "text" : "password"}
                            setShowPassword={setShowPassword}
                            showPassword={showPassword}
                            inputType="password"
                            autoComplete="off"
                          />
                        </div>
                        <Link
                          className="mb-8 forgetText align-center"
                          onClick={forgetPass}
                        >
                          Forget Password?
                        </Link>
                      </div>
                      <div className="pt-4 flex justify-center mb-10">
                        <div className="w-full h:34 md:h-42 lg:h-51">
                          <Button
                            onClick={handleSubmit}
                            text="Log in"
                            filled
                            className="buttonClass"
                            disabled={
                              values.username === "" ||
                              values.password === "" ||
                              !isValid
                            }
                            loading={false}
                          />
                        </div>
                      </div>
                    </form>
                  </Form>
                )}
              </Formik>
              <p className="loginBottomText font-normal text-darkest font-body-large text-center">
                You don't have an account yet?{" "}
                <a
                  className="font-bold text-primary cursor-pointer"
                  onClick={() => {
                    signup(true);
                    setLoginModal(false);
                  }}
                >
                  {"  "}Sign up
                </a>
              </p>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

const Search = ({ showSearch, toggleSearch }) => {
  return showSearch ? (
    <div className="flex w-48 sm:w-64  border border-primary-2 pl-4 py-2 rounded-2xl">
      <input
        type="search"
        placeholder="Search Cases"
        className="w-4/5 bg-transparent"
      />
      <Icon onClick={toggleSearch} />
    </div>
  ) : (
    <Icon onClick={toggleSearch} />
  );
};

function Header() {
  const [showSearch, setShowSearch] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const errorAlert = useSelector(({ store }) => store.errorAlertReducer);
  const siteSearchData = useSelector(
    ({ store }) => store.siteSearchReducer.data
  );
  const [confirmModal, setConfirmModal] = useState(false);
  const [signupModal, setSignupModal] = useState(false);
  const [studyModuleRes, setStudyModuleRes] = useState([]);
  const loading = useSelector(({ store }) => store.siteSearchReducer.loading);
  const [module, setModule] = useState([]);
  const [loginModal, setLoginModal] = useState(false);
  const [searchChange, setSearchChange] = useState("Type to Search");
  const [checkBox, setCheckBox] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const validateSchema = Yup.object({
    email: Yup.string().email("Invalid email").required("Email is required"),
    username: Yup.string()
      .required("Username is required")
      .min(5, "Minimum 5 characters")
      .max(26, "Maximum 26 characters"),
    password2: Yup.string().oneOf(
      [Yup.ref("password1"), null],
      "Passwords must match"
    ),
    password1: Yup.string()
      .required("Password is required.")
      .min(7, "Password is too short - should be 6+ chars minimum.")
      .matches(
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
        "Password should have at least one capital letter & one digit."
      ),
  });

  const toggleSearch = useCallback(
    (event) => {
      event.preventDefault();
      setShowSearch(!showSearch);
    },
    [showSearch]
  );

  const confirmAppoint = async (status) => {
    let url_appoint = window.location.href.split("appointment=");
    let obj;
    if (status !== "") {
      obj = {
        status: status,
        appointment_id: parseInt(url_appoint[1]),
      };
    }

    await apiRequest({
      method: "post",
      url: "content/confirm/appointment/",
      data: obj,
    }).then((res) => {
      if (res?.data?.data.includes("Confirmed")) {
        setTimeout(() => {
          dispatch(
            createErrorAlert(
              201,
              "Success",
              "Congratulations! Your Free Appointment has been confirmed",
              201
            )
          );
        }, 2000);
        setConfirmModal(false);
        history.push("/");
      } else {
        setTimeout(() => {
          dispatch(
            createErrorAlert(
              201,
              "Success",
              "Your Free Appointment has been cancelled",
              201
            )
          );
        }, 2000);
        setConfirmModal(false);
        history.push("/");
      }
    });
  };

  const studyModuleData = async () => {
    await apiRequest({
      method: "get",
      url: "studies/all/studymodules",
    }).then((res) => {
      setStudyModuleRes(res?.data);
    });
  };

  useEffect(() => {
    studyModuleData();
    dispatch(initialError());
    ReactGA.pageview(window.location.pathname + window.location.search);
    if (window.location.href.includes("free_tutor")) {
      setConfirmModal(true);
    }
  }, []);

  useEffect(() => {
    if (errorAlert?.showAlert) {
      document.body.style.overflowY = "hidden";
      document.body.style.position = "fixed";
    } else if (!errorAlert?.showAlert) {
      document.body.style.overflowY = "unset";
      document.body.style.position = "unset";
    }
    if (siteSearchData) {
      if (siteSearchData.length > 0) {
        let modules = [];
        siteSearchData.map((item) => {
          if (item?.title?.includes("summary")) {
            return null;
          } else {
            modules.push({
              value: item,
              label: `${slugToText(item.title)}  -  ${item.type}`,
            });
          }
        });
        setModule(modules);
      }
    }
  }, [errorAlert, siteSearchData]);

  const handleSelectChange = (change) => {
    setSearchChange(
      change.length > 0
        ? "We cannot find what you are looking for. You can search by case names or legal principles e.g., consideration"
        : change.length === 0
          ? "Type to Search"
          : "Type To Search"
    );
    if (change.length > 0) {
      dispatch(getSiteSearch(change));
    }
  };

  const handleSelectedModule = (selected) => {
    if (studyModuleRes.length > 0) {
      for (let i = 0; i < studyModuleRes.length; i++) {
        if (selected.value.type === "Note") {
          if (
            parseInt(selected?.value?.study_module.id) ===
            parseInt(studyModuleRes[i].id) ||
            parseInt(selected?.value?.id) === parseInt(studyModuleRes[i].id)
          ) {
            localStorage.setItem('studyModule', studyModuleRes[i].slug)
            if (selected.value.type === "Note") {
              history.push({
                pathname: `/${studyModuleRes[i].slug}/notes/${selected.value.slug}`,
                state: { selected },
              });
              window.location.replace(`/${studyModuleRes[i].slug}/notes/${selected.value.slug}`)
            }
          }
        } else {
          localStorage.setItem("studyModule", studyModuleRes[i].slug);
          if (
            parseInt(selected?.value?.study_module) ===
            parseInt(studyModuleRes[i].id) ||
            parseInt(selected?.value?.id) === parseInt(studyModuleRes[i].id) || parseInt(selected?.value?.study_module?.id) === parseInt(studyModuleRes[i].id)
          ) {
            if (selected.value.type === "StudyModule") {
              history.push({
                pathname: `/study-module/${selected.value.slug}`,
                state: { selected },
              });
            } else if (selected.value.type === "Essay") {
              dispatch(
                createErrorAlert(
                  211,
                  "Info",
                  "To view Model Essays please Login. No SimpleStudy account? Sign Up",
                  211
                )
              );
            } else if (selected.value.type === "CaseStudy") {
              history.push({
                pathname: `/${selected.value.slug}/`,
                state: { selected },
              });
              window.location.replace(`/${selected.value.slug}/`)
            } else if (selected.value.type === "TutorialVideo") {
              // window.location.href = selected.value.url;
              dispatch(
                createErrorAlert(
                  211,
                  "Info",
                  "To view Tutorial Videos please Login. No SimpleStudy account? Sign Up",
                  211
                )
              );
            } else if (selected.value.type === "Quiz") {
              dispatch(
                createErrorAlert(
                  211,
                  "",
                  "To view Quiz please Login",
                  211
                )
              );
              // history.push({
              //   pathname: `/quiz/${selected.value.slug}/`,
              //   state: {
              //     state: selected.value.slug,
              //     value: studyModuleRes[i].slug,
              //   },
              // });
            } else if (selected.value.type === "FlashCard") {
              dispatch(
                createErrorAlert(
                  200,
                  "Info",
                  `To view Flash Cards you must be logged in. No SimpleStudy account?`,
                  200
                )
              );
              // history.push({ pathname: `/${studyModuleRes[i].slug}/flashcards` });
            }
          }
        }
      }
    }
  };

  const logoClick = () => {
    if (getApiKey()) {
      history.push(`/profile`);
    } else {
      history.push(`/`);
    }
  };
  const contactUs = () => {
    setBurgerMenuOpen(false);
    history.push("/contact-us");
  };

  const handleClose = () => {
    setSignupModal(false);
    setLoginModal(false);
  };

  const signupRoute = (location) => {
    if (location.pathname === "/") {
      history.push("/signup");
    } else {
      setSignupModal(true);
    }
  };
  const onSignUp = async (values) => {
    if (checkBox === true) {
      await apiRequest({
        method: "post",
        url: `accounts/dj-rest-auth/registration`,
        data: values,
      }).then((res) => {
        setKey(res.data.access_token);
        setSignupModal(false);
        if (getApiKey()) {
          window.location.reload();
        }
      });
    } else {
      dispatch(
        createErrorAlert(
          201,
          "Error",
          "Please agree to the terms and conditions",
          201
        )
      );
    }
  };

  const tutoring = () => {
    setBurgerMenuOpen(false);
    history.push("/boost-your-grades");
  };

  const checked = (e) => {
    if (e === true) {
      setCheckBox(true);
    } else {
      setCheckBox(false);
    }
  };
  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);
  const toggleBurgerMenu = () => {
    console.log(burgerMenuOpen, "ppp"); // Add this line

    setBurgerMenuOpen(!burgerMenuOpen);
  };
  const BurgerMenu = ({ isOpen, toggleMenu }) => {
    return (
      <div className="relative flex justify-center items-center w-fit text-center mx-3 mt-3 custom-sm:mt-0">
        {/* <Button
                text="Here"
                onClick={toggleMenu}    
                className="burger-icon"
                filled
              /> */}
        {/* <div className="relative w-max">
          <img
            src={Burger}
            alt="Burger"
            onClick={toggleMenu}
            style={{ cursor: "pointer" }}
          />
        </div> */}
        {/* <ul className={`burger-menu ${isOpen ? "open" : ""}`}>
          <li className=" cursor-pointer mt-4" onClick={toggleMenu}>
            <div className="z-50 absolute -right-4 top-2 px-8 cursor-pointer mt-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 closeIcon"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          </li>
          <li className="mt-8">
            <p
              className=" custom:text-14  font-bold mt-12 text-left"
              onClick={contactUs}
              id="menu"
            >
              Contact us
            </p>
          </li>
          <li className="mt-4">
            <p
              className=" custom:text-14  font-bold text-left"
              onClick={tutoring}
              id="menu"
            >
              Tutoring
            </p>
          </li>
        </ul> */}
      </div>
    );
  };
  return (
    <div
      className={`${(history.location.pathname === "/study-module/:slug" ||
          history.location.pathname === "/signup" ||
          history.location.pathname === "/login" ||
          history.location.pathname === "/signup/step-one" ||
          history.location.pathname === "/signup/step-two" ||
          history.location.pathname === "/forget-password" ||
          history.location.pathname.includes("auth/reset-password/") ||
          history.location.pathname === "/signup/step-three") &&
        "hidden"
        }`}
    >
      <FullStory org={ORG_ID} />
      <ErrorHandler
        isOpen={errorAlert?.showAlert}
        closeModal={(e) => dispatch(closeAlert(e))}
        simpleClose={() => dispatch(initialError())}
        title={errorAlert?.title}
        status={errorAlert?.status}
        content={errorAlert?.message}
      />
      <Container>
        {/* SMALL HEADER */}
        <div className="smallHeader items-center justify-between pt-4 pb-7 lg:hidden xl:hidden 2xl:hidden">
          <BurgerMenu isOpen={burgerMenuOpen} toggleMenu={toggleBurgerMenu} />

          <Logo
            showSearch={showSearch}
            handleClick={logoClick}
            source={Logos}
          />
          <div className="sHBtnSec flex items-center">
            {/* <Select
              placeholder="Search Cases, Topics, Legal Principles, Study Modules etc"
              values={module}
              handleSelected={handleSelectedModule}
              valueChange={handleSelectChange}
              noOptionMessage={searchChange}
              loading={loading}
            /> */}
            <div className="searchWrap  searchWrap2 searchWrapTwo md:w-80 sm:w-64 block lg:hidden">
              <Select
                placeholder={"Search"}
                values={module}
                id="topSearch"
                handleSelected={handleSelectedModule}
                valueChange={handleSelectChange}
                noOptionMessage={searchChange}
                loading={loading}
              />
              <div className="searchIcon">
                <img src={search_icon} alt="search_icon" width="23" />
              </div>
            </div>
            <div className="signUISec">
              <LoginButton signup={setSignupModal} loginModals={loginModal} />
              <div className="homeSignUpBtn block lg:hidden custom2:w-40">
                <Button
                  text="Sign Up"
                  onClick={() => signupRoute(window.location)}
                  filled
                />
              </div>
            </div>
          </div>
        </div>

        {/* BIGGER HEADER */}
        <div className="hidden lg:flex md:justify-between items-center py-2 md:py-8 lg:py-14">
          <div
            onClick={() => {
              history.push("/");
            }}
          >
            <div
              className="custom:hidden"
              onClick={() => {
                history.push("/");
              }}
            >
              {/*<Logo/>*/}
            </div>
            <div
              onClick={() => {
                history.push("/");
              }}
            >
              <Logo
                showSearch={showSearch}
                handleClick={logoClick}
                source={Logos}
                asdsad
              />
            </div>
          </div>

          <div className="searchWrap searchWrap2 searchWrapTwo md:w-80 sm:w-64 md:block hidden">
            <Select
              placeholder="Search"
              values={module}
              id="topSearch"
              handleSelected={handleSelectedModule}
              valueChange={handleSelectChange}
              noOptionMessage={searchChange}
              loading={loading}
            />
            <div className="searchIcon">
              <img src={search_icon} alt="search_icon" width="23" />
            </div>
          </div>
          <div className="flex items-center">
            <LoginButton signup={setSignupModal} loginModals={loginModal} />
            <div className="hidden md:block custom2:w-40">
              <Button
                text="Sign Up"
                onClick={() => signupRoute(window.location)}
                filled
              />
            </div>
          </div>
          {/* <div className="nav-item px-2.5 pr-0 hidden 1200:block">
            <p
              className="btn flex-auto border-2 border-primary md:border-0 ml-1 text-black py-2 px-2 font-bold xl:w-full"
              style={{ cursor: "pointer" }}
              onClick={contactUs}
            >
              Contact Us
            </p>
          </div> */}
          {/* <div className="nav-item px-2.5 pr-0 hidden 1200:block">
            <p
              className="btn flex-auto border-2 border-primary md:border-0 ml-1 text-primary py-2 px-2 font-bold xl:w-full"
              style={{ cursor: "pointer" }}
              onClick={tutoring}
            >
              Tutoring
            </p>
          </div> */}
        </div>
        <Modal
          open={signupModal}
          onClose={handleClose}
          focusTrapped={false}
          center
          classNames={{
            overlay: "custom-overlay-class",
            modal: "react-modal-class1",
          }}
        >
          {/* <GoogleAuthentication />
            <FacebookAuthentication /> */}
          {/* <div className="flex flex-row justify-center items-center px-2 lg:px-6 my-2 lg:my-7">
            <div className="border border-ligthBorderGrey w-2/5"></div>
              <p className="px-3.5 text-darkest font-body-large ">or</p>
              <div className="border border-ligthBorderGrey w-2/5"></div>
          </div> */}
          <div className="flex gap-4">
            <div className="w-full hidden md:block">
              <p className="text-body-large  font-bold text-left md:text-h3 xl:text-36  mr-1">
                Join the movement of{" "}
                <span className="text-yellowish">26K savvy StudyCrowd.AI members</span>{" "}
                already empowered - and you're next
              </p>

              <p className=" text-body-small text-left pt-6 pr-2 md:text-h5 pr-2 xl:text-36 ">
                Discover the secret to success as you become part of our growing
                community.
              </p>
              <p className="text-body-small text-left pt-6 pr-2 md:text-h5 pr-2 xl:text-36 "
              >
                Find simple and concise study resources created by top graduates who took the same courses as you at US and UK universities</p>
              <div className="flex justify-around mt-10 items-center flex-col lg:flex-row">
                {/* <div className=" text-center">
                  <div className="w-116 h-100">
                    <img src={img1} className="m-auto mt-3 w-full h-full" />
                  </div>
                  <p className="  fw-bold text-42 text-yellowish ">35+</p>
                  <p>
                    Study Modules
                    <br />
                    All main areas of English law
                  </p>
                </div> */}
                <div className=" text-center">
                  <div className="w-150 h-150">
                    <img src={img2} className="m-auto mt-3 w-full h-full" />
                  </div>
                  <p className="fw-bold text-42  text-yellowish">75K</p>
                  <p>
                    Active Users
                    <br />
                    in 2024
                  </p>
                </div>
                <div className=" text-center">
                  <div className="w-150 h-150">
                    <img src={img3} className="m-auto mt-3 w-full h-full" />
                  </div>
                  <p className="fw-bold text-42  text-yellowish">1600+</p>
                  <p>
                    Hours Tutoring <br />
                    Delivered
                  </p>
                </div>
              </div>
            </div>

            <div>
              <Formik
                initialValues={{
                  password1: "",
                  username: "",
                  email: "",
                  password2: "",
                }}
                validationSchema={validateSchema}
                onSubmit={(values) => onSignUp(values)}
              >
                {({ isValid, handleSubmit, values }) => (
                  <Form>
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="flex flex-col text-darkest items-center">
                        <p className="loginHeadText text-darkest font-bold text-body-large mb-10 md:text-h3 xl:text-36 text-center">
                          Sign in to{" "}
                          <span className="text-yellowish">StudyCrowd.AI</span>
                        </p>
                        <div className="w-full mb-3 md:mb-9 ">
                          <p className="mb-8">Email</p>
                          <Field
                            name="email"
                            as={Input}
                            placeholder="Enter your email"
                            type="text"
                          />
                        </div>

                        <div className="w-full mb-3 md:mb-9 ">
                          <p className="mb-8">First Name</p>
                          <Field
                            name="username"
                            as={Input}
                            placeholder="Enter your First Name"
                            type="text"
                          />
                        </div>

                        <div className="w-full ">
                          <p className="mb-8">Password</p>
                          <Field
                            name="password1"
                            as={Input}
                            placeholder="Enter your password"
                            type={showPassword ? "text" : "password"}
                            setShowPassword={setShowPassword}
                            showPassword={showPassword}
                            inputType="password"
                          />
                          <p className="text-small text-darkGrey2 my-8">
                            6+ characters, 1 capital letter{" "}
                          </p>
                        </div>
                        <div className="w-full mb-10 md:mb-14">
                          <p className="mb-8">Confirm password</p>
                          <Field
                            name="password2"
                            as={Input}
                            placeholder="Enter your confirm password"
                            type={showConfirmPassword ? "text" : "password"}
                            setShowPassword={setShowConfirmPassword}
                            showPassword={showConfirmPassword}
                            inputType="password"
                          />
                        </div>
                        <div
                          className="w-full mb-10 md:mb-14"
                          style={{ display: "flex" }}
                        >
                          <label className="condContainer">
                            {" "}
                            I agree to{" "}
                            <a
                              style={{
                                color: "#cc6828",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                history.push("/terms-conditions");
                              }}
                            >
                              Terms and Conditions
                            </a>
                            ,{" "}
                            <a
                              style={{
                                color: "#cc6828",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                history.push("/privacy-policy");
                              }}
                            >
                              Privacy Policy
                            </a>{" "}
                            and{" "}
                            <a
                              style={{
                                color: "#cc6828",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                history.push("/cookies-policy");
                              }}
                            >
                              Cookies Policy
                            </a>{" "}
                            of Simple Studying{" "}
                            <input
                              id="condBox"
                              onClick={(e) => checked(e.target.checked)}
                              type="checkbox"
                              className="default:ring-2 text-primary mr-3 checkBoxOne"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                      <div className="pt-4 flex justify-center mb-10">
                        <div className="w-full h-51">
                          <Button
                            onClick={handleSubmit}
                            text="Sign up for free"
                            filled
                            className="buttonClass"
                            disabled={
                              values.username === "" ||
                              values.password1 === "" ||
                              values.password2 === "" ||
                              values.email === "" ||
                              !isValid ||
                              values.checked === "false"
                            }
                          // loading={loader}
                          />
                        </div>
                      </div>
                    </form>
                  </Form>
                )}
              </Formik>
            </div>
          </div>

          <p className="font-normal text-darkest font-body-large text-center">
            Already have an account?{" "}
            <a
              className="font-bold text-primary cursor-pointer"
              onClick={() => {
                setLoginModal(true);
                setSignupModal(false);
              }}
            >
              {"  "}Log in
            </a>
          </p>
        </Modal>
        <Modal open={confirmModal} focusTrapped={false} center>
          <div>
            <p className="text-darkest md:text-h3 font-bold mb-5">
              Please CONFIRM OR CANCEL your tutoring session at StudyCrowd.AI
            </p>
            <div className="flex flex-col justify-center items-center md:justify-between md:flex-row md:space-x-5 w-full mt-4 md:mt-10">
              <div className="w-56">
                <Button
                  simple
                  onClick={() => {
                    confirmAppoint("CANCEL");
                  }}
                >
                  <p className="text-center text-14 xl:text-body-large">
                    Cancel
                  </p>
                </Button>
              </div>
              <div className="w-56 mt-5 md:mt-0">
                <Button
                  filled
                  onClick={() => {
                    confirmAppoint("CONFIRM");
                  }}
                >
                  <p className="text-center text-14 xl:text-body-large">
                    Confirm
                  </p>
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </Container>
    </div>
  );
}

export default withRouter(Header);
