import React from "react";
import { useHistory } from "react-router-dom";
import Button from "../../components/EmptyButton";


const Step2Content = ({ handleNext }) => {
    const history = useHistory()
  return (
    <div>
      <ul className="stepper-ul">
        <li>
          UK users: On average 45% of undergraduate students in the UK universities drop out.<br/>
          US users: On average 40% of undergraduate students in the US universities drop out.
        </li>
        <li>
          When students start their degrees, they are inundated with thousands of pages to read in dozens of complicated textbooks in addition to thousands of hours of lectures. As a result, students get confused, frustrated and often fail their exams.        </li>
        <li>
          The consequences of dropping out are serious. University dropouts often struggle to find well-paying jobs.
        </li>
        <li>
          At StudyCrowd.AI, our goal is to help you acheive high grades. We are also pertnering with career advisors and employers to connect you with suitable internships and job oppurtunities. We're here to support you from day one of your higher education journey through graduation and beyond, maximising your chances of academic success and securing a well-paid career. 
        </li>
        <li>
          You need very good grades to get a graduate job in this competitive
          job market. The best paying graduate jobs are usually given to graduates with high grades.
        </li>
      </ul>
      <div className="flex flex-col sm-md:flex-row justify-between px-26 pb-6  sm-md:space-x-10 md:space-x-24">
        <Button onClick={() => {history.push('/')}} className='mb-5 mt-5 sm-md:mt-0'>
          Keep Subscription
        </Button>
        <Button
          onClick={handleNext}
        >
          Request Cancellation
        </Button>
      </div>
    </div>
  );
};
export default Step2Content;
