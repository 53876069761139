import React, {useEffect, useState} from "react";
import Trophy from "../../assets/icons/trophy.svg";
import Essays from "../../assets/images/main-page-materials/essays.webp";
import notes from "../../assets/images/main-page-materials/notes.webp";
import platform from "../../assets/images/main-page-materials/platform.svg";
import quiz from "../../assets/images/main-page-materials/quiz.webp";
import tips from "../../assets/images/main-page-materials/tips.svg";
import StudyMaterial from "../../components/StudyMaterials";
import tutorials from "../../assets/images/main-page-materials/tutorials.webp";
import Button from "../../components/EmptyButton";
import Frame1 from "../../assets/images/subFrame1.png"
import Frame2 from "../../assets/images/subFrame2.png"
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { getCatalogue } from "../../redux/actions";
import { catalogueData } from "../../redux/selectors";
import { slugToText } from "../../helpers";

const Step1Content = ({ handleNext, dataCatalogue, getCatalogue }) => {
  const user = JSON.parse(localStorage.getItem("app_user"));
  const history = useHistory()
  const [paidModules, setPaidModules] = useState([])

  const materials = [
    {
      id: 1,
      name: (
        <p className="text-small-text mt-2.5 mb-12 text-center custom:text-body-large custom:font-bold">
          Revision <br className="hidden xl:block" /> Notes
        </p>
      ),
      image: notes,
    },
    {
      id: 2,
      name: (
        <p className="text-small-text mt-2.5 mb-12 text-center custom:text-body-large custom:font-bold">
          Model <br className="hidden xl:block" /> Essays
        </p>
      ),
      image: Essays,
    },
  ];

  useEffect(() => {
    getCatalogue()
  },[])

  useEffect(() => {
    for(let i=0; i<dataCatalogue?.data?.length; i++){
        if(dataCatalogue?.data[i]?.is_paid === true){
            if(!paidModules.includes(dataCatalogue?.data[i]?.study_module?.slug)){
                paidModules.push(dataCatalogue?.data[i]?.study_module?.slug)
            }            
        }
    }

},[dataCatalogue])


  return (
    <div>
      <div className="flex flex-col sm-md:flex-row gap-5 justify-center ">
        <div className=" w-full bg-white">
          <div className="mt-5">
            <p>
              {user?.first_name} we would be sorry if you had lost your access
              to our simple study materials made by top university students and graduates helping you to get high grades without reading thousands of pages in overcomplicated textbooks.
            </p>
            <br/>
            <p>List of Study modules you'll be losing access to:
            </p>
            <ul className="ml-4 mt-4">
              {paidModules.length > 0 && paidModules.map((item) => {
                return(
                  <li style={{listStyle: 'bullet'}}>{slugToText(item)}</li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className="mt-14 lg:flex justify-center space-x-36">
        {materials.map((elem) => (
          <div className="w-116 xl:w-44">
            <StudyMaterial img={elem.image} alt={elem.name}>
              {elem.name}
            </StudyMaterial>
          </div>
        ))}
      </div>
      <div className="flex flex-col sm-md:flex-row justify-between px-26 pb-6  sm-md:space-x-10 md:space-x-24">
        <Button onClick={() => {history.push('/')}} className='mb-5 mt-5 sm-md:mt-0'>
          Keep Subscription
        </Button>
        <Button
          onClick={handleNext}
        >
          Request Cancellation
        </Button>
      </div>
      <p className="text-center">
        Note: You could cancel later. You will be reminded 7 days before each
        subscription renewal.{" "}
      </p>
    </div>
  );
};

const mapStateToProps = (state) => ({
  dataCatalogue: catalogueData(state),
});

const mapDispatchToProps = (dispatch) => ({
    getCatalogue: (id) => dispatch(getCatalogue(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Step1Content);
