import React from 'react'

import Container from '../../components/container'
import FacebookIcon from '../../assets/images/facebookIcon.png'
import TwitterIcon from '../../assets/images/twitterIcon.png'
import Video1Image from '../../assets/images/video4.webp'
import Video2Image from '../../assets/images/video2.webp'
import Video3Image from '../../assets/images/video3.webp'
import PlayButton from '../../assets/images/playButtonPaid.png'
import LazyImage from '../../components/LazyImage'
import Header from '../../components/PlanYourStudyModule/Header'
import { useHistory } from "react-router-dom";
import Button from '../../components/EmptyButton'

const StudyMaterials = ({ image, cardTitle, date = "03.08.2021.", altText = "image" }) => (
    <div className='bg-white md:w-50 lg:w-72 custom2:w-472 cursor-pointer' style={{ boxShadow: "4px 8px 20px rgba(2, 23, 54, 0.03)", borderRadius: '25px' }}>
        <div className="relative" >
            <LazyImage src={image} alt='image' />
            <img className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-70 custom2:w-100" src={PlayButton} alt="imagefb" />
        </div>
        <div className='p-6'>
            <p className='font-bold md:text-h5 xl:text-h4 mb-9'>{cardTitle}</p>
            <div className="flex justify-between items-center">
                <div className="flex items-center">
                    <img className="mr-4 md:mr-6" src={FacebookIcon} alt="imagefb" />
                    <img src={TwitterIcon} alt="imagefb" />
                </div>
                <p>{date}</p>
            </div>
        </div>
    </div>
)

const ContentCreatorPortal = () => {

    const history = useHistory()
    return (
        <div className='bg-white bg-subscription-bottom bg-bottom bg-no-repeat'>
            <Header />
            <Container>
                <div className='pt-10 lg:pt-14 flex' >
                    <div>
                        <p className='tutorialVidText custom2:text-36 font-bold'>Welcome to your content creator portal!</p>
                        <p className='text-body-large custom2:mt-3'>Your training: We recommend you to watch the following videos to help you maximise your earnings at StudyCrowd.AI</p>
                    </div>
                </div>
                <div className='flex md:space-x-3 custom2:space-x-8 flex-col md:flex-row pt-4 md:pt-10 ' >
                    <div className='pt-10 md:pt-0 w-full md:w-1/3 flex  justify-center'>
                        <StudyMaterials image={Video1Image} cardTitle="How to do marketing?" />
                    </div>
                    <div className='pt-10 md:pt-0 w-full md:w-1/3 flex justify-center '>
                        <StudyMaterials image={Video2Image} cardTitle="How to maximise your earnings?" />
                    </div>
                    <div className='pt-10 md:pt-0 w-full md:w-1/3 flex justify-center'>
                        <StudyMaterials image={Video3Image} cardTitle="How to do marketing?" />
                    </div>
                </div>
                <div className='py-20'>
                    <div className='flex flex-col items-center md:flex-row justify-center md:px-20'>
                        <div className='w-10/12 md:w-80  custom2:w-472'>
                            <Button onClick={() => history.push("/content-creator/step-three")} filled>
                                <p className='text-center text-14 xl:text-body-large'>START UPLOADING YOUR CONTENT</p>
                            </Button>
                        </div>

                    </div>
                </div>
            </Container>
        </div>
    )
}

export default ContentCreatorPortal
