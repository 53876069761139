import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Container from "../../components/container";
import BreadCrumb from "../../components/BreadCrumb";
import InfoBox from "../../components/InfoBox";
import Button from "../../components/EmptyButton";
import LazyImage from "../../components/LazyImage";
import InfoImg from "../../assets/icons/information.svg";
import Essays from "../../assets/images/main-page-materials/essays.webp";
import Notes from "../../assets/images/main-page-materials/notes.webp";
import Platform from "../../assets/images/main-page-materials/case-summaries.webp";
import Quiz from "../../assets/images/main-page-materials/quiz.webp";
import Tips from "../../assets/images/main-page-materials/fashcard.webp";
import Tutorials from "../../assets/images/main-page-materials/tutorials.webp";
import ArrowLeft from "../../assets/icons/arrowLeft.webp";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { apiRequest, getApiKey, getUser, slugToText } from "../../helpers";
import {
  createErrorAlert,
  getCatalogue,
  getSpecificStudyModuleDetail,
  setValue,
} from "../../redux/actions";
import {
  catalogueData,
  specificModuleData,
  specificModuleError,
  specificModuleLoading,
} from "../../redux/selectors";
import { Helmet } from "react-helmet";
import bgIMG from "../../assets/newImages/Group 1391.png";
import books from "../../assets/newImages/Group 1393.png";
import Context from "../../services/Context";

const StudyModulePage = ({ getSpecificStudyModuleDetail, data }) => {
  const dispatch = useDispatch();
  const param = useParams();
  const history = useHistory();
  const location = useLocation();

  const [state, setState] = useState({
    moduleDetail: data,
    showInfo: false,
    paidModules: [],
    user: JSON.parse(getUser()),
  });

  const breadCrumbItem = [{ title: "Get study modules", link: "/" }];

  const { value } = useContext(Context);
  const userCurrency = value?.userCurrency || state?.user?.user_currency;

  useEffect(() => {
    dispatch(setValue(param.slug));
    getSpecificStudyModuleDetail(param.slug);
  }, []);

  useEffect(() => {
    new window.carouselInlineWidget("reviewsio-carousel-widget", {
      /*Your REVIEWS.io account ID:*/
      store: "www.simplestudying.com",
      sku: "",
      lang: "en",
      carousel_type: "default",
      styles_carousel: "CarouselWidget--sideHeader",

      /*Widget settings:*/
      options: {
        general: {
          /*What reviews should the widget display? Available options: company, product, third_party. You can choose one type or multiple separated by comma.*/
          review_type: "company, product",
          /*Minimum number of reviews required for widget to be displayed*/
          min_reviews: "1",
          /*Maximum number of reviews to include in the carousel widget.*/
          max_reviews: "20",
          address_format: "CITY, COUNTRY",
          /*Carousel auto-scrolling speed. 3000 = 3 seconds. If you want to disable auto-scroll, set this value to false.*/
          enable_auto_scroll: 10000,
        },
        header: {
          /*Show overall rating stars*/
          enable_overall_stars: true,
          rating_decimal_places: 2,
        },
        reviews: {
          /*Show customer name*/
          enable_customer_name: true,
          /*Show customer location*/
          enable_customer_location: true,
          /*Show "verified review" badge*/
          enable_verified_badge: true,
          /*Show "verified subscriber" badge*/
          enable_subscriber_badge: true,
          /*Show "I recommend this product" badge (Only for product reviews)*/
          enable_recommends_badge: true,
          /*Show photos attached to reviews*/
          enable_photos: true,
          /*Show videos attached to reviews*/
          enable_videos: true,
          /*Show when review was written*/
          enable_review_date: true,
          /*Hide reviews written by the same customer (This may occur when customer reviews multiple products)*/
          disable_same_customer: true,
          /*Minimum star rating*/
          min_review_percent: 4,
          /*Show 3rd party review source*/
          third_party_source: true,
          /*Hide reviews without comments (still shows if review has a photo)*/
          hide_empty_reviews: true,
          /*Show product name*/
          enable_product_name: true,
          /*Show only reviews which have specific tags (multiple semicolon separated tags allowed i.e tag1;tag2)*/
          tags: "",
          /*Show branch, only one input*/
          branch: "",
          enable_branch_name: false,
        },
        popups: {
          /*Make review items clickable (When they are clicked, a popup appears with more information about a customer and review)*/
          enable_review_popups: true,
          /*Show "was this review helpful" buttons*/
          enable_helpful_buttons: true,
          /*Show how many times review was upvoted as helpful*/
          enable_helpful_count: true,
          /*Show share buttons*/
          enable_share_buttons: true,
        },
      },
      translations: {
        verified_customer: "Verified Customer",
      },
      styles: {
        /*Base font size is a reference size for all text elements. When base value gets changed, all TextHeading and TexBody elements get proportionally adjusted.*/
        "--base-font-size": "16px",
        "--base-maxwidth": "100%",

        /*Logo styles:*/
        "--reviewsio-logo-style": "var(--logo-normal)",

        /*Star styles:*/
        "--common-star-color": " #0E1311",
        "--common-star-disabled-color": " rgba(0,0,0,0.25)",
        "--medium-star-size": " 22px",
        "--small-star-size": "19px" /*Modal*/,
        "--x-small-star-size": "16px",
        "--x-small-star-display": "inline-flex",

        /*Header styles:*/
        "--header-order": "1",
        "--header-width": "160px",
        "--header-bg-start-color": "transparent",
        "--header-bg-end-color": "transparent",
        "--header-gradient-direction": "135deg",
        "--header-padding": "0.5em",
        "--header-border-width": "0px",
        "--header-border-color": "rgba(0,0,0,0.1)",
        "--header-border-radius": "0px",
        "--header-shadow-size": "0px",
        "--header-shadow-color": "rgba(0, 0, 0, 0.1)",

        /*Header content styles:*/
        "--header-star-color": "inherit",
        "--header-disabled-star-color": "inherit",
        "--header-heading-text-color": "inherit",
        "--header-heading-font-size": "inherit",
        "--header-heading-font-weight": "inherit",
        "--header-heading-line-height": "inherit",
        "--header-heading-text-transform": "inherit",
        "--header-subheading-text-color": "inherit",
        "--header-subheading-font-size": "inherit",
        "--header-subheading-font-weight": "inherit",
        "--header-subheading-line-height": "inherit",
        "--header-subheading-text-transform": "inherit",

        /*Review item styles:*/
        "--item-maximum-columns": "5" /*Must be 3 or larger*/,
        "--item-background-start-color": "transparent",
        "--item-background-end-color": "transparent",
        "--item-gradient-direction": "135deg",
        "--item-padding": "0.5em",
        "--item-border-width": "0px",
        "--item-border-color": "rgba(0,0,0,0.1)",
        "--item-border-radius": "0px",
        "--item-shadow-size": "0px",
        "--item-shadow-color": "rgba(0,0,0,0.1)",

        /*Heading styles:*/
        "--heading-text-color": " #0E1311",
        "--heading-text-font-weight": " 600",
        "--heading-text-font-family": " inherit",
        "--heading-text-line-height": " 1.4",
        "--heading-text-letter-spacing": "0",
        "--heading-text-transform": "none",

        /*Body text styles:*/
        "--body-text-color": " #0E1311",
        "--body-text-font-weight": "400",
        "--body-text-font-family": " inherit",
        "--body-text-line-height": " 1.4",
        "--body-text-letter-spacing": "0",
        "--body-text-transform": "none",

        /*Scroll button styles:*/
        "--scroll-button-icon-color": "#0E1311",
        "--scroll-button-icon-size": "24px",
        "--scroll-button-bg-color": "transparent",

        "--scroll-button-border-width": "0px",
        "--scroll-button-border-color": "rgba(0,0,0,0.1)",

        "--scroll-button-border-radius": "60px",
        "--scroll-button-shadow-size": "0px",
        "--scroll-button-shadow-color": "rgba(0,0,0,0.1)",
        "--scroll-button-horizontal-position": "0px",
        "--scroll-button-vertical-position": "0px",

        /*Badge styles:*/
        "--badge-icon-color": "#0E1311",
        "--badge-icon-font-size": "15px",
        "--badge-text-color": "#0E1311",
        "--badge-text-font-size": "inherit",
        "--badge-text-letter-spacing": "inherit",
        "--badge-text-transform": "inherit",

        /*Author styles:*/
        "--author-font-size": "inherit",
        "--author-font-weight": "inherit",
        "--author-text-transform": "inherit",

        /*Product photo or review photo styles:*/
        "--photo-video-thumbnail-size": "60px",
        "--photo-video-thumbnail-border-radius": "0px",

        /*Popup styles:*/
        "--popup-backdrop-color": "rgba(0,0,0,0.75)",
        "--popup-color": "#ffffff",
        "--popup-star-color": "inherit",
        "--popup-disabled-star-color": "inherit",
        "--popup-heading-text-color": "inherit",
        "--popup-body-text-color": "inherit",
        "--popup-badge-icon-color": "inherit",
        "--popup-badge-icon-font-size": "19px",
        "--popup-badge-text-color": "inherit",
        "--popup-badge-text-font-size": "14px",
        "--popup-border-width": "0px",
        "--popup-border-color": "rgba(0,0,0,0.1)",
        "--popup-border-radius": "0px",
        "--popup-shadow-size": "0px",
        "--popup-shadow-color": "rgba(0,0,0,0.1)",
        "--popup-icon-color": "#0E1311",

        /*Tooltip styles:*/
        "--tooltip-bg-color": "#0E1311",
        "--tooltip-text-color": "#ffffff",
      },
    });
  }, []);

  useEffect(() => {
    if (data) {
      data.map((item) => {
        let custom = {
          name: item.title,
          short_description: item.short_description,
          price: item.price,
          id: item.id,
          author: item.author,
          slug: item.slug,
        };
        setState((prev) => ({
          ...prev,
          moduleDetail: custom,
        }));
      });
    }
  }, [data]);
  useEffect(() => {
    for (let i = 0; i < value?.dataCatalogue?.length; i++) {
      if (value?.dataCatalogue[i]?.is_paid === true) {
        if (
          !state.paidModules?.includes(
            value?.dataCatalogue[i]?.study_module?.slug
          )
        ) {
          state.paidModules?.push(value?.dataCatalogue[i]?.study_module.slug);
        }
      }
    }
  }, [value?.dataCatalogue]);

  const essays = () => {
    if (
      (state.user?.subscription_name === "Basic Subscription" ||
        state.user?.subscription_name.includes("STARTER")) &&
      state.user?.free_trial === false &&
      state.user?.user_type === "STUDENT" &&
      state.paidModules.includes(param.slug)
    ) {
      history.push({ pathname: `/${param.slug}/model-essays` });
      return;
    }
    if (
      getApiKey() &&
      state.user?.subscription_name !== "Basic Subscription" &&
      !state.user?.subscription_name.includes("STARTER") &&
      state.user?.user_type === "STUDENT"
    ) {
      if (state.moduleDetail.author !== 1) {
        if (state.paidModules.includes(state.moduleDetail.slug)) {
          history.push({ pathname: `/${param.slug}/model-essays` });
        } else {
          dispatch(
            createErrorAlert(
              211,
              "Info",
              "You have to buy this module to access Model Essays",
              211
            )
          );
        }
      } else {
        history.push({ pathname: `/${param.slug}/model-essays` });
      }
    }
    if (getApiKey() && state.user?.user_type === "TEACHER") {
      history.push({ pathname: `/${param.slug}/model-essays` });
    }
    if (!getApiKey()) {
      dispatch(
        createErrorAlert(
          211,
          "Info",
          "To view Model Essays please Login. No SimpleStudy account? Sign Up",
          211
        )
      );
    }
    if (
      state.user?.subscription_name === "Basic Subscription" &&
      state.user?.free_trial === true
    ) {
      history.push({ pathname: `/${param.slug}/model-essays` });
    }
    if (
      (state.user?.subscription_name === "Basic Subscription" ||
        state.user?.subscription_name.includes("STARTER")) &&
      state.user?.free_trial === false &&
      state.user?.user_type === "STUDENT"
    ) {
      history.push({ pathname: `/${param.slug}/model-essays` });
      // dispatch(
      //   createErrorAlert(
      //     203,
      //     "Info",
      //     "To view Model Essays you must have a premium subscription",
      //     203
      //   )
      // );
    }
  };

  const tutorials = () => {
    if (
      (state.user?.subscription_name === "Basic Subscription" ||
        state.user?.subscription_name.includes("STARTER")) &&
      state.user?.free_trial === false &&
      state.user?.user_type === "STUDENT" &&
      state.paidModules.includes(param.slug)
    ) {
      history.push({
        pathname: `/${param.slug}/tutorial-videos`,
        state: { paidModules: state.paidModules },
      });
      return;
    }
    if (
      getApiKey() &&
      state.user?.subscription_name !== "Basic Subscription" &&
      !state.user?.subscription_name.includes("STARTER") &&
      state.user?.user_type === "STUDENT"
    ) {
      if (state.moduleDetail.author !== 1) {
        if (state.paidModules.includes(state.moduleDetail.slug)) {
          history.push({ pathname: `/${param.slug}/tutorial-videos` });
        } else {
          dispatch(
            createErrorAlert(
              211,
              "Info",
              "You have to buy this module to access Tutorial Videos",
              211
            )
          );
        }
      } else {
        history.push({ pathname: `/${param.slug}/tutorial-videos` });
      }
    }
    if (!getApiKey()) {
      dispatch(
        createErrorAlert(
          211,
          "Info",
          "To view Tutorial Videos please Login. No SimpleStudy account? Sign Up",
          211
        )
      );
    }
    if (
      state.user?.subscription_name === "Basic Subscription" &&
      state.user?.free_trial === false &&
      state.user?.user_type === "STUDENT"
    ) {
      dispatch(
        createErrorAlert(
          203,
          "",
          "To view Tutorial Videos you must have a Premium subscription.",
          203
        )
      );
    }
    if (
      state.user?.subscription_name === "Basic Subscription" &&
      state.user?.free_trial === true
    ) {
      history.push({ pathname: `/${param.slug}/tutorial-videos` });
    }
    if (state.user?.user_type !== "STUDENT") {
      history.push({ pathname: `/${param.slug}/tutorial-videos` });
    }
    if (state.user?.subscription_name.includes("STARTER")) {
      dispatch(
        createErrorAlert(
          203,
          "",
          "To view Tutorial Videos you must have a Premium subscription.",
          203
        )
      );
    }
  };
  const flashCards = () => {
    if (
      (state.user?.subscription_name === "Basic Subscription" ||
        state.user?.subscription_name.includes("STARTER")) &&
      state.user?.free_trial === false &&
      state.user?.user_type === "STUDENT" &&
      state.paidModules.includes(param.slug)
    ) {
      history.push({
        pathname: `/${param.slug}/flashcards`,
        state: { paidModules: state.paidModules },
      });
      return;
    }
    if (
      getApiKey() &&
      state.user?.subscription_name !== "Basic Subscription" &&
      !state.user?.subscription_name.includes("STARTER") &&
      state.user?.user_type === "STUDENT"
    ) {
      if (state.moduleDetail.author !== 1) {
        if (state.paidModules.includes(state.moduleDetail.slug)) {
          history.push({ pathname: `/${param.slug}/flashcards` });
        } else {
          dispatch(
            createErrorAlert(
              211,
              "Info",
              "You have to buy this module to access Flash Cards",
              211
            )
          );
        }
      } else {
        history.push({ pathname: `/${param.slug}/flashcards` });
      }
    }
    if (!getApiKey()) {
      dispatch(
        createErrorAlert(
          200,
          "",
          `To view Flash Cards you must be logged in. No SimpleStudy account?`,
          200
        )
      );
    }
    if (
      state.user?.subscription_name === "Basic Subscription" &&
      state.user?.free_trial === false
    ) {
      dispatch(
        createErrorAlert(
          203,
          "",
          "To view Flash Cards you must have a Premium subscription.",
          203
        )
      );
    }
    if (
      state.user?.subscription_name === "Basic Subscription" &&
      state.user?.free_trial === true
    ) {
      history.push({ pathname: `/${param.slug}/flashcards` });
    }
    if (state.user?.user_type !== "STUDENT") {
      history.push({ pathname: `/${param.slug}/flashcards` });
    }
    if (state.user?.subscription_name.includes("STARTER")) {
      dispatch(
        createErrorAlert(
          203,
          "",
          "To view Flash Cards you must have a Premium subscription.",
          203
        )
      );
    }
  };

  const quizClick = () => {
    if (getApiKey()) {
      if (
        state.user?.subscription_name === "Basic Subscription" &&
        !state?.user?.subscription_name.includes("STARTER") &&
        state.user?.free_trial === false
      ) {
        dispatch(
          createErrorAlert(
            800,
            "You discovered a Premium feature!",
            "This is premium content that is not available with a free subscription. It is designed to help you achieve high grades. Unlock it now!",
            800
          )
        );
      } else if (
        state.user?.subscription_name === "Basic Subscription" &&
        state.user?.free_trial === true
      ) {
        history.push(`/${param.slug}/quizzes`);
      } else if (state.user?.subscription_name !== "Basic Subscription") {
        history.push(`/${param.slug}/quizzes`);
      }
    } else {
      dispatch(
        createErrorAlert(
          800,
          "You discovered a Premium feature!",
          "This is premium content that is not available with a free subscription. It is designed to help you achieve high grades. Start your free trial to unlock it!",
          800
        )
      );
    }
  };

  const handleBasket = async () => {
    if (getApiKey()) {
      let obj = {
        study_module: state.moduleDetail?.id,
      };
      await apiRequest({
        method: "post",
        url: "content/basket/",
        data: obj,
      })
        .then((res) => {
          if (res.status === 201) {
            history.push("/shopping-basket");
          }
        })
        .catch((err) => {
          dispatch(createErrorAlert(400, "", err.response.data[0], 400));
        });
    } else {
      history.push("/susbcription");
    }
  };

  const handleRouteTutoring = () => {
    history.push("/boost-your-grades");
  };
  return (
    <div className="bg-white">
      <div className="bg-offWhite">
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Simple Studying | Study Module | {slugToText(param.slug)}
          </title>
          <meta name="description" content="Study Modules Description" />
          <link
            rel="canonical"
            href={`https://simplestudying.com/study-module/${param.slug}`}
          />
          <meta name="robots" content="index, follow" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:locale:alternate" content="en_gb" />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content={`Simple Studying | Study Module | ${slugToText(
              param.slug
            )}`}
          />
          <meta property="og:description" content="Study Modules Description" />
          <meta
            property="og:url"
            content={`https://simplestudying.com/study-module/${param.slug}`}
          />
          <meta property="og:site_name" content="Simple Studying" />
        </Helmet>
        <Container>
          {getApiKey() ? (
            <div className="pt-10 custom:pt-14">
              <BreadCrumb
                items={breadCrumbItem}
                current={slugToText(param.slug)}
              />
            </div>
          ) : null}

          <div className="flex justify-between pt-10 custom:pt-14">
            <div className="flex gap-2 flex-col">
              <div className=" flex items-center relative">
                <img
                  src={InfoImg}
                  alt="info-img"
                  className="mr-1 w-16 md:w-24 custom:w-5 custom:mr-4"
                  onMouseEnter={() =>
                    setState((prev) => ({
                      ...prev,
                      showInfo: true,
                    }))
                  }
                  onMouseLeave={() =>
                    setState((prev) => ({
                      ...prev,
                      showInfo: false,
                    }))
                  }
                />
                {state.showInfo && (
                  <div className="tooltipSSec  absolute z-10 top-14 -left-6">
                    <InfoBox text={state.moduleDetail?.short_description} />
                  </div>
                )}
                <p className="text-body-large font-bold text-14 sm:text-14 md:text-h4 custom:text-h3">
                  {slugToText(param.slug) === "It Law"
                    ? "IT Law"
                    : slugToText(param.slug)}
                </p>
              </div>
              {state.paidModules?.includes(param.slug) &&
              getApiKey() &&
              userCurrency === "UK" ? (
                <p className="mb-0 text-body-small md:text-body-large font-normal">
                  You enrolled in this study module, and you have access to it forever subject to our{" "}
                  <span 
                    className="text-blue cursor-pointer" 
                    onClick={() => {
                      history.push("/terms-conditions");
                    }} 
                    style={{ textDecoration: "underline" }}
                  >
                    terms
                  </span>. 
                  All the study modules you enrolled in are added to{" "}
                  <span
                    className="text-blue cursor-pointer"
                    onClick={() => {
                      history.push("/library");
                    }}
                    style={{ textDecoration: "underline" }}
                  >
                    your library
                  </span>
                </p>
              ) : state?.user?.subscription_name !== "Basic Subscription" && getApiKey() &&
                !state?.user?.subscription_name.includes("STARTER") ? (
                <p className="mb-0 text-body-small md:text-body-large font-normal">
                  As a premium subscriber, you have access to all study
                  materials, but for a limited time! If you want to access
                  certain study modules indefinitely with a one-time fee, get
                  them separately, without a subscription.
                </p>
              ) : null}
            </div>
            <div className="custom:flex items-center">
              <div className="custom:w-64 xl:w-340">
                {!state.paidModules.includes(param.slug) &&
                  getApiKey() &&
                  (state.user?.subscription_name === "Basic Subscription" ||
                    state.user?.subscription_name?.includes("STARTER")) && (
                    <Button filled onClick={handleBasket}>
                      <p className="custom:text-13 xl:text-body-large text-center px-5">
                        Get All{" "}
                        {slugToText(param.slug) === "It Law"
                          ? "IT Law"
                          : slugToText(param.slug)}{" "}
                        <br className="custom:hidden xl:block" /> Materials FOR
                        ONLY £{state.moduleDetail.price / 100}
                      </p>
                    </Button>
                  )}
              </div>
              {(state.user?.subscription_name === "Basic Subscription" ||
                state.user?.subscription_name?.includes("STARTER")) && state.moduleDetail.author === 1 && userCurrency === "UK" ? (
                <div className="mx-7">
                  <p className="font-bold text-h5">OR</p>
                </div>
              ) : null}
              <div className="w-12/12 xl:w-96">
                {state.user?.subscription_name === "Basic Subscription" ||
                state.user?.subscription_name?.includes("STARTER") && state.moduleDetail.author === 1 && userCurrency === "UK" ? (
                  <Button filled onClick={() => history.push("/subscription")}>
                    <p className="custom:text-13 xl:text-body-large text-center px-5">
                      Become a premium member to access everything!{" "}
                      <br className="custom:hidden xl:block" /> <br/>Activate your FREE TRIAL now
                    </p>
                  </Button>
                ) : null}
              </div>
              {!getApiKey() && (
                <Button filled onClick={() => history.push("/subscription")}>
                  <p className="custom:text-13 xl:text-body-large text-center px-5">
                    Get All the Study Modules{" "}
                    <br className="custom:hidden xl:block" /> for £11.25/MONTH
                  </p>
                </Button>
              )}
            </div>
          </div>
        </Container>
      </div>
      <div className="bg-study-module-back bg-no-repeat bg-top bg-contain">
        <Container>
          <div className="flex flex-col md:flex-row justify-between gap-3">
            <div
              className="pt-16 pb-16 grid grid-cols-2 md:grid-cols-3 gap-y-14 lg:gap-y-16 custom:gap-y-20 justify-items-center custom:px-1 flex-1"
              style={{ flex: "70%", flexBasis: "70%" }}
            >
              {data[0]?.notes !== 0 ? (
                <div
                  className="w-32 h-32 
                          lg:w-11rem lg:h-11rem
                           xl:w-72 xl:h-72 cursor-pointer"
                  onClick={() =>
                    history.push({ pathname: `/${param.slug}/notes` })
                  }
                >
                  <LazyImage alt={""} src={Notes} className="h-full w-full" />
                  <p className="text-center font-bold text-14 lg:text-body-large">
                    Revision Notes
                  </p>
                </div>
              ) : null}

              {data[0]?.summaries !== 0 ? (
                <div
                  className="w-32 h-32  lg:w-56 lg:h-56 custom:w-60 custom:h-60 xl:w-72 xl:h-72 cursor-pointer"
                  onClick={() =>
                    history.push({
                      pathname: `/case-studies/${param.slug}`,
                      state: { paidModules: state.paidModules },
                    })
                  }
                >
                  <LazyImage alt={""} src={Platform} />
                  <p className="text-center font-bold text-14 lg:text-body-large xl:text-h4">
                    Case Summaries
                  </p>
                </div>
              ) : null}

              {data[0]?.essays !== 0 ? (
                <div
                  className="w-32 h-32 lg:w-11rem lg:h-11rem  xl:w-72 xl:h-72 cursor-pointer"
                  onClick={essays}
                >
                  <LazyImage alt={""} src={Essays} />
                  <p className="text-center font-bold text-14 lg:text-body-large xl:text-h4">
                    Model Essays
                  </p>
                </div>
              ) : null}
              {/* custom:w-60 custom:h-60 */}
              {data[0]?.quiz ? (
                <div
                  className="w-32 h-32  lg:w-11rem lg:h-11rem  xl:w-72 xl:h-72 cursor-pointer"
                  onClick={() => quizClick()}
                >
                  <LazyImage alt={""} src={Quiz} />
                  <p className="text-center font-bold text-14 lg:text-body-large xl:text-h4">
                    Quiz
                  </p>
                </div>
              ) : null}

              {data[0]?.tutorial_videos !== 0 ? (
                <div
                  className="w-32 h-32  lg:w-11rem lg:h-11rem  xl:w-72 xl:h-72 cursor-pointer"
                  onClick={tutorials}
                >
                  <LazyImage alt={""} src={Tutorials} />
                  <p className="text-center font-bold text-14 lg:text-body-large xl:text-h4">
                    Tutorial Videos
                  </p>
                </div>
              ) : null}

              {data[0]?.flash_cards !== 0 ? (
                <div
                  className="w-32 h-32  lg:w-11rem lg:h-11rem xl:w-72 xl:h-72 cursor-pointer"
                  onClick={flashCards}
                >
                  <LazyImage alt={""} src={Tips} />
                  <p className="text-center font-bold text-14 lg:text-body-large xl:text-h4 mt-6 md:mt-5 lg:mt-9 custom:mt-12">
                    Flashcards
                  </p>
                </div>
              ) : null}
            </div>
            {state.user?.is_law === true && userCurrency === "UK" && <div
              className="bg-white relative flex-1 p-5 flex flex-col text-center rounded-3xl h-max my-16 mt-20"
              style={{
                flex: "30%",
                flexBasis: "30%",
                boxShadow: "4px 8px 20px rgba(2, 23, 54, 0.05)",
                background: " rgb(255,255,255)",
                background:
                  " linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 51%, rgba(231,231,231,1) 99%)",
              }}
            >
              <div className="m-auto">
                <img src={books} className="m-auto" />
              </div>
              <div className="absolute bottom-0 z-0">
                <img src={bgIMG} />
              </div>
              <p className="mb-5">
                Hey there, future legal eagles! While our study materials set
                you up well for that coveted First Class, let’s face it: many of
                us need that extra boost to nail that law degree.
              </p>
              <p className="mb-5">
                Level up your game with personalised tutoring. It’s the secret
                sauce to securing that top-grade status!
              </p>
              <button
                onClick={handleRouteTutoring}
                className="
                  flex items-center fw-semibold z-10
                  text-primary text-14 text-center m-auto p-2 mt-2 border-primary border-2 hover:text-white hover:bg-primary"
              >
                Get your tutoring now and soar to success! 🚀{" "}
              </button>
            </div>}
            
          </div>
        </Container>
        <Container>
          <div className="pb-8 pt-8 md:pt-20 lg:pb-28 md:pb-18 flex justify-center">
            <div className="md:w-80 custom:w-96 backToSearchBtn">
              {getApiKey() ? (
                <Button onClick={() => history.push("/")}>
                  <p className=" text-darkest text-small-text px-2 md:font-body-large flex items-center md:text-15 xl:py-2">
                    <img src={ArrowLeft} alt="arrow" className="mr-2" />
                    Back to Searching Study Materials
                  </p>
                </Button>
              ) : null}
            </div>
          </div>
          <div className="mt-20" id="reviewsio-carousel-widget"></div>
        </Container>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  data: specificModuleData(state),
  loading: specificModuleLoading(state),
  error: specificModuleError(state),
});
const mapDispatchToProps = (dispatch) => ({
  getSpecificStudyModuleDetail: (slug) =>
    dispatch(getSpecificStudyModuleDetail(slug)),
});
export default connect(mapStateToProps, mapDispatchToProps)(StudyModulePage);
