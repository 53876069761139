import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import "./index.css";
import { FaCheckCircle, FaTimesCircle, FaBookOpen } from "react-icons/fa";
import { Modal } from "react-responsive-modal";
import WelcomeVideo from "../../assets/images/welcome-video.svg";
import ProfileImage from "../../assets/images/defaultDp.webp";
import EditPassword from "../../assets/images/forgot.svg";
import { Pencil } from "../../components/Icons";
import InfoBox from "../../components/InfoBox/SmallInfobox";
import Button from "../../components/EmptyButton";
import session from "../../assets/images/session.svg";
import { Cap } from "../../components/Icons";
import { University } from "../../components/Icons";
import { FaBell, FaCalendar, FaCreditCard } from "react-icons/fa";
import completeProfileSvg from "../../assets/images/completeProfile.svg";
import timeSlot from "../../assets/images/time slot.svg";
import InfoImg from "../../assets/icons/information.svg";
import {
  catalogueData,
  studyModulesData,
  userData,
} from "../../redux/selectors";
import {
  createErrorAlert,
  getCatalogue,
  getSpecificStudyModules,
  getUserInfo,
} from "../../redux/actions";
import VideoPlayer from "../../components/VideoPlayer";
import LazyImage from "../../components/LazyImage";
import StudyMaterials from "../../components/StudyModules/StudyMaterials";
import ResetUserPasswordModal from "../../components/Modal/ResetPassword";
import EditUserInfoModal from "../../components/Modal/EditProfile";
import { apiRequest, getApiKey, getUser } from "../../helpers";
import { Helmet } from "react-helmet";
import { FiAlertTriangle } from "react-icons/fi";
import { Container } from "postcss";
import step1Img from "../../assets/newImages/Group 79.png";
import step2Img from "../../assets/newImages/Group 82.png";
import step3Img from "../../assets/newImages/Group 89.png";
import Context from "../../services/Context";

const ProfilePage = (props) => {
  const {
    user_detail,
    // getUserInfo,
    studyModulesData,
    getSpecificStudyModules,
    dataCatalogue,
    getCatalogue,
  } = props;

  const dispatch = useDispatch();

  const [recommendation, setRecommendation] = useState([]);
  const [user, setUser] = useState(JSON.parse(getUser()));
  const [isOpenForEdit, setIsOpenForEdit] = useState(false);
  const [isOpenForPassword, setIsOpenForPassword] = useState(false);
  const [showInfo, setShowInfo] = useState(null);
  const [showCancelMessage, setShowCancelMessage] = useState(
    localStorage.getItem("cancelSubscription")
  );
  const [paidModules, setPaidModules] = useState([]);
  const [trialExpiredInfo, setTrialExpiredInfo] = useState(false);
  const [completeProfile, setCompleteProfile] = useState(false);

  const [cancelSubscriptionModal, setCancelSubscriptionModal] = useState(false)

  const { value, updateValue } = useContext(Context);
  const userCurrency = value?.userCurrency || user?.user_currency;
  const userIsLaw = value?.is_law || user?.is_law;


  const today = new Date();

  today.setDate(today.getDate() + 3);

  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = today.toLocaleDateString("en-US", options);

  // useEffect(() => {
  //   let icon = document.getElementById('complete-icon')
  //   if(trialExpiredInfo === true){
  //     if(icon){
  //       icon.style.display = 'none'
  //     }
  //   } else{
  //     if(icon){
  //       icon.style.display = 'block'
  //     }
  //   }
  // })

  const history = useHistory();

  useEffect(() => {
    new window.carouselInlineWidget("reviewsio-carousel-widget", {
      /*Your REVIEWS.io account ID:*/
      store: "www.simplestudying.com",
      sku: "",
      lang: "en",
      carousel_type: "default",
      styles_carousel: "CarouselWidget--sideHeader",

      /*Widget settings:*/
      options: {
        general: {
          /*What reviews should the widget display? Available options: company, product, third_party. You can choose one type or multiple separated by comma.*/
          review_type: "company, product",
          /*Minimum number of reviews required for widget to be displayed*/
          min_reviews: "1",
          /*Maximum number of reviews to include in the carousel widget.*/
          max_reviews: "20",
          address_format: "CITY, COUNTRY",
          /*Carousel auto-scrolling speed. 3000 = 3 seconds. If you want to disable auto-scroll, set this value to false.*/
          enable_auto_scroll: 10000,
        },
        header: {
          /*Show overall rating stars*/
          enable_overall_stars: true,
          rating_decimal_places: 2,
        },
        reviews: {
          /*Show customer name*/
          enable_customer_name: true,
          /*Show customer location*/
          enable_customer_location: true,
          /*Show "verified review" badge*/
          enable_verified_badge: true,
          /*Show "verified subscriber" badge*/
          enable_subscriber_badge: true,
          /*Show "I recommend this product" badge (Only for product reviews)*/
          enable_recommends_badge: true,
          /*Show photos attached to reviews*/
          enable_photos: true,
          /*Show videos attached to reviews*/
          enable_videos: true,
          /*Show when review was written*/
          enable_review_date: true,
          /*Hide reviews written by the same customer (This may occur when customer reviews multiple products)*/
          disable_same_customer: true,
          /*Minimum star rating*/
          min_review_percent: 4,
          /*Show 3rd party review source*/
          third_party_source: true,
          /*Hide reviews without comments (still shows if review has a photo)*/
          hide_empty_reviews: true,
          /*Show product name*/
          enable_product_name: true,
          /*Show only reviews which have specific tags (multiple semicolon separated tags allowed i.e tag1;tag2)*/
          tags: "",
          /*Show branch, only one input*/
          branch: "",
          enable_branch_name: false,
        },
        popups: {
          /*Make review items clickable (When they are clicked, a popup appears with more information about a customer and review)*/
          enable_review_popups: true,
          /*Show "was this review helpful" buttons*/
          enable_helpful_buttons: true,
          /*Show how many times review was upvoted as helpful*/
          enable_helpful_count: true,
          /*Show share buttons*/
          enable_share_buttons: true,
        },
      },
      translations: {
        verified_customer: "Verified Customer",
      },
      styles: {
        /*Base font size is a reference size for all text elements. When base value gets changed, all TextHeading and TexBody elements get proportionally adjusted.*/
        "--base-font-size": "16px",
        "--base-maxwidth": "100%",

        /*Logo styles:*/
        "--reviewsio-logo-style": "var(--logo-normal)",

        /*Star styles:*/
        "--common-star-color": " #0E1311",
        "--common-star-disabled-color": " rgba(0,0,0,0.25)",
        "--medium-star-size": " 22px",
        "--small-star-size": "19px" /*Modal*/,
        "--x-small-star-size": "16px",
        "--x-small-star-display": "inline-flex",

        /*Header styles:*/
        "--header-order": "1",
        "--header-width": "160px",
        "--header-bg-start-color": "transparent",
        "--header-bg-end-color": "transparent",
        "--header-gradient-direction": "135deg",
        "--header-padding": "0.5em",
        "--header-border-width": "0px",
        "--header-border-color": "rgba(0,0,0,0.1)",
        "--header-border-radius": "0px",
        "--header-shadow-size": "0px",
        "--header-shadow-color": "rgba(0, 0, 0, 0.1)",

        /*Header content styles:*/
        "--header-star-color": "inherit",
        "--header-disabled-star-color": "inherit",
        "--header-heading-text-color": "inherit",
        "--header-heading-font-size": "inherit",
        "--header-heading-font-weight": "inherit",
        "--header-heading-line-height": "inherit",
        "--header-heading-text-transform": "inherit",
        "--header-subheading-text-color": "inherit",
        "--header-subheading-font-size": "inherit",
        "--header-subheading-font-weight": "inherit",
        "--header-subheading-line-height": "inherit",
        "--header-subheading-text-transform": "inherit",

        /*Review item styles:*/
        "--item-maximum-columns": "5" /*Must be 3 or larger*/,
        "--item-background-start-color": "transparent",
        "--item-background-end-color": "transparent",
        "--item-gradient-direction": "135deg",
        "--item-padding": "0.5em",
        "--item-border-width": "0px",
        "--item-border-color": "rgba(0,0,0,0.1)",
        "--item-border-radius": "0px",
        "--item-shadow-size": "0px",
        "--item-shadow-color": "rgba(0,0,0,0.1)",

        /*Heading styles:*/
        "--heading-text-color": " #0E1311",
        "--heading-text-font-weight": " 600",
        "--heading-text-font-family": " inherit",
        "--heading-text-line-height": " 1.4",
        "--heading-text-letter-spacing": "0",
        "--heading-text-transform": "none",

        /*Body text styles:*/
        "--body-text-color": " #0E1311",
        "--body-text-font-weight": "400",
        "--body-text-font-family": " inherit",
        "--body-text-line-height": " 1.4",
        "--body-text-letter-spacing": "0",
        "--body-text-transform": "none",

        /*Scroll button styles:*/
        "--scroll-button-icon-color": "#0E1311",
        "--scroll-button-icon-size": "24px",
        "--scroll-button-bg-color": "transparent",

        "--scroll-button-border-width": "0px",
        "--scroll-button-border-color": "rgba(0,0,0,0.1)",

        "--scroll-button-border-radius": "60px",
        "--scroll-button-shadow-size": "0px",
        "--scroll-button-shadow-color": "rgba(0,0,0,0.1)",
        "--scroll-button-horizontal-position": "0px",
        "--scroll-button-vertical-position": "0px",

        /*Badge styles:*/
        "--badge-icon-color": "#0E1311",
        "--badge-icon-font-size": "15px",
        "--badge-text-color": "#0E1311",
        "--badge-text-font-size": "inherit",
        "--badge-text-letter-spacing": "inherit",
        "--badge-text-transform": "inherit",

        /*Author styles:*/
        "--author-font-size": "inherit",
        "--author-font-weight": "inherit",
        "--author-text-transform": "inherit",

        /*Product photo or review photo styles:*/
        "--photo-video-thumbnail-size": "60px",
        "--photo-video-thumbnail-border-radius": "0px",

        /*Popup styles:*/
        "--popup-backdrop-color": "rgba(0,0,0,0.75)",
        "--popup-color": "#ffffff",
        "--popup-star-color": "inherit",
        "--popup-disabled-star-color": "inherit",
        "--popup-heading-text-color": "inherit",
        "--popup-body-text-color": "inherit",
        "--popup-badge-icon-color": "inherit",
        "--popup-badge-icon-font-size": "19px",
        "--popup-badge-text-color": "inherit",
        "--popup-badge-text-font-size": "14px",
        "--popup-border-width": "0px",
        "--popup-border-color": "rgba(0,0,0,0.1)",
        "--popup-border-radius": "0px",
        "--popup-shadow-size": "0px",
        "--popup-shadow-color": "rgba(0,0,0,0.1)",
        "--popup-icon-color": "#0E1311",

        /*Tooltip styles:*/
        "--tooltip-bg-color": "#0E1311",
        "--tooltip-text-color": "#ffffff",
      },
    });
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
    // getUserInfo();
    getSpecificStudyModules();
    getCatalogue(null);
  }, []);
  useEffect(() => {
    for (let i = 0; i < dataCatalogue?.data?.length; i++) {
      if (dataCatalogue?.data[i]?.is_paid === true) {
        if (
          !paidModules?.includes(dataCatalogue?.data[i]?.study_module?.slug)
        ) {
          paidModules?.push(dataCatalogue?.data[i]?.study_module.slug);
        }
      }
    }
  }, [dataCatalogue]);
  useEffect(() => {
    if (user_detail) {
      setUser(user_detail);
    }
    if (studyModulesData) {
      if (studyModulesData.length > 0) {
        let recommend = [];
        studyModulesData.map((item) => {
          recommend.push({
            value: item.title,
            label: item.title,
            item: item,
          });
        });
        setRecommendation(recommend);
      }
    }
  }, [user_detail, studyModulesData]);
  const upgradeMembership = (name) => {
    if (
      name === "GET COVERED FOR THE EXAM PERIOD" ||
      name === "IDEAL IF YOU AIM FOR A FIRST CLASS" ||
      name === "QUICK BOOST OF YOUR GRADES"
    ) {
      history.push({ pathname: `/all-subscriptions` });
    } else if (name === "Basic Subscription") {
      history.push({ pathname: `/subscription` });
    } else {
      history.push({ pathname: `/all-subscriptions` });
    }
  };

  const headingHandle = (subscription) => {
    if (subscription?.subscription_name !== "Basic Subscription") {
      if (subscription?.subscription_name === "QUICK BOOST OF YOUR GRADES") {
        return "PREMIUM ALL INCLUSIVE SUBSCRIPTION WITH MONTHLY BILLING";
      } else if (
        subscription?.subscription_name === "GET COVERED FOR THE EXAM PERIOD"
      ) {
        return "PREMIUM ALL INCLUSIVE SUBSCRIPTION WITH QUARTERLY BILLING";
      } else if (
        subscription?.subscription_name === "IDEAL IF YOU AIM FOR A FIRST CLASS"
      ) {
        return "PREMIUM ALL INCLUSIVE SUBSCRIPTION WITH ANNUAL BILLING";
      } else if (
        subscription?.subscription_name ===
        "STARTER PACK FOR IMPROVING YOUR GRADES (MONTHLY BILLED)"
      ) {
        return "BASIC STARTER PACK SUBSCRIPTION WITH MONTHLY BILLING";
      } else if (
        subscription?.subscription_name ===
        "STARTER PACK FOR IMPROVING YOUR GRADES (QUARTERLY BILLED)"
      ) {
        return "BASIC STARTER PACK SUBSCRIPTION WITH QUARTERLY BILLING";
      } else if (
        subscription?.subscription_name ===
        "STARTER PACK FOR IMPROVING YOUR GRADES (ANNUALLY BILLED)"
      ) {
        return "BASIC STARTER PACK SUBSCRIPTION WITH ANNUAL BILLING";
      }
    } else if(user?.free_trial === true && user?.subscription_name === "Basic Subscription") {
      return "Free Trial in Progress";
    } else{
      return "Free Subscription"
    }
  };

  const handleInfoSubscription = (user) => {
    if (user?.subscription_name === "Basic Subscription") {
      if (userCurrency === "UK") {
        return "The free subscription lasts forever. However, there is a pop-up that blocks half of the screen on our case summaries and short notes. Also, premium notes that are essential for high grades are not accessible at all. <br/> You can unlock everything with our premium membership. <br/> Our premium membership includes everything you need <b>to get a First-Class law degree!</b><br/> Activate your free trial now!";
      } else {
        return "The free subscription lasts forever. However, there is a pop-up that blocks half of the screen on our premium study materials like simple notes and sample answers to exam questions. Those study materials are essential for high grades are not accessible at all. <br/> You can unlock everything by purchasing the study modules you need <b>to get top grades!</b><br/> Activate your free trial now! ";
      }
    } else if (
      headingHandle(user) ===
        "PREMIUM ALL INCLUSIVE SUBSCRIPTION WITH MONTHLY BILLING" ||
      headingHandle(user) ===
        "PREMIUM ALL INCLUSIVE SUBSCRIPTION WITH QUARTERLY BILLING" ||
      headingHandle(user) ===
        "PREMIUM ALL INCLUSIVE SUBSCRIPTION WITH ANNUAL BILLING"
    ) {
      return "This subscription includes everything you need to get high grades without reading big and complicated textbooks! You should use our simple and condensed study materials every day to prepare for your exams for achieving First Class. <br /> <br /> Additionally, one to one tutoring is highly recommended to further improve your chances of a First Class. ";
    } else if (
      headingHandle(user) ===
        "BASIC STARTER PACK SUBSCRIPTION WITH MONTHLY BILLING" ||
      headingHandle(user) ===
        "BASIC STARTER PACK SUBSCRIPTION WITH QUARTERLY BILLING" ||
      headingHandle(user) ===
        "BASIC STARTER PACK SUBSCRIPTION WITH ANNUAL BILLING"
    ) {
      return "Saving you thousands of hours in case law research whilst helping you to improve your grades. <br /> <br /> However, this subscription does not give you access to the full notes that contain the crucial information for getting a First Class. Also, it does not give you access to the example essays, tutorial videos, quizzes and flashcards which are all essential content for a First Class. <br /> <br /> If you want unlimited access to all the study materials to maximise your chances of getting a First Class law degree, you should upgrade to the premium for just £10/month.";
    }
  };

  const slugName = (selected) => {
    history.push({
      pathname: `/study-module/${selected?.item?.slug}`,
      state: { selected },
    });
  };

  const mouseEnter = (item) => {
    if (item === 0) {
      document.getElementById(1).style.display = "none";
      document.getElementById(2).style.display = "none";
    } else if (item === 1) {
      document.getElementById(2).style.display = "none";
    }
  };

  const mouseLeave = (item) => {
    if (item === 0) {
      document.getElementById(1).style.display = "block";
      document.getElementById(2).style.display = "block";
    } else if (item === 1) {
      document.getElementById(2).style.display = "block";
    }
  };

  const handleCancelSubscription = () => {
    history.push('/subscription-cancel')
  }

  const handleCancelMessage = () => {
    localStorage.removeItem("cancelSubscription");
    setShowCancelMessage(false);
  };

  const handleRoute = (value) => {
    if (value === "tutoring") {
      history.push("/boost-your-grades");
    } else if(value === "content"){
      history.push('/content-creator/step-zero')
    } else {
      history.push("/");
    }
  };

  const handleZoom = async () => {
    await apiRequest({
      method: "get",
      url: `accounts/zoom-user/`,
    })
      .then((res) => {
        dispatch(
          createErrorAlert(
            200,
            "",
            res.data.detail + ", kindly check you email to approve",
            200
          )
        );
      })
      .catch((err) => {
        dispatch(createErrorAlert(400, "", err.response.data.response, 400));
      });
  };

  return (
    <div className="bg-white profile-page-container">
      {window.location.pathname === "/profile" ? (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Simple Studying | Profile</title>
          <meta name="description" content="Profile Description" />
          <link rel="canonical" href="https://simplestudying.com/profile" />
          <meta name="robots" content="index, follow" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:locale:alternate" content="en_gb" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Simple Studying | Profile" />
          <meta property="og:description" content="Profile Description" />
          <meta
            property="og:url"
            content="https://test.simplestudying.com/profile"
          />
          <meta property="og:site_name" content="Simple Studying" />
        </Helmet>
      ) : null}

      <div className="profile-info bg-offWhite">
        {showCancelMessage && (
          <>
          <div class="borderMessage mb-4">
            <div class="flex items-center">
              <div class="mr-4 text-primary text-42">
                <FiAlertTriangle />
              </div>
              <div class="text-left px-4">
                Your Premium Subscription will expire {formattedDate}. <br/>
                You will no longer be charged and you will lose all the benefits of StudyCrowd.AI on this date.
              </div>
              <div class="ml-auto text-primary cursor-pointer">
                <FaTimesCircle onClick={() => handleCancelMessage()} />
              </div>
            </div>
          </div>
          <div class="borderMessage mb-4 mt-4">
          <div class="flex items-center">
            <div class="mr-4 text-primary text-42">
              <FiAlertTriangle />
            </div>
            <div class="text-left px-4">
              After the free trial expires you will no longer have access to the simple study materials to maximise your chances of getting high grades to have <br/>
              a successful career. You can check out study materials recommended for you if you changed your mind.
            </div>
            <div class="ml-auto text-primary cursor-pointer">
              <FaTimesCircle onClick={() => handleCancelMessage()} />
            </div>
          </div>
        </div>
          </>
        )}

        {user?.user_type === "STUDENT" && userIsLaw === true && userCurrency === "UK" ? (
          <div className="flex justify-end">
            <p
              className="underline text-primary font-bold text-h5 mb-30 md:text-body-large upgrade"
              onClick={() => upgradeMembership(user?.subscription_name)}
            >
              {!getApiKey()
                ? "JOIN NOW"
                : user?.subscription_name === "Basic Subscription"
                ? ""
                : user?.subscription_name !== "Basic Subscription"
                ? "VIEW MEMBERSHIP PLAN"
                : "UPGRADE MEMBERSHIP"}
            </p>
          </div>
        ) : null}
        {/*{user?.user_type === 'STUDENT' && user?.subscription_name !== 'Basic Subscription' ?*/}
        {/*    <div className="flex justify-end">*/}
        {/*        <p*/}
        {/*            className="underline text-primary font-bold text-h5 mb-30 md:text-body-large upgrade"*/}
        {/*            onClick={subscriptionList}*/}
        {/*        >*/}
        {/*            Subscriptions*/}
        {/*        </p>*/}
        {/*    </div>*/}
        {/*    : null}*/}
        {/*{user?.user_type === 'STUDENT' && user?.subscription_name !== 'Basic Subscription' ?*/}
        {/*    <div className="flex justify-end">*/}
        {/*        <p*/}
        {/*            className="underline text-primary font-bold text-h5 mb-30 md:text-body-large upgrade"*/}
        {/*            onClick={manageSubscriptions}*/}
        {/*        >*/}
        {/*            Manage Subscriptions*/}
        {/*        </p>*/}
        {/*    </div>*/}
        {/*    : null*/}
        {/*}*/}
        <div className="flex profileFlex">
          <div className="flex-1 profileWrap flex">
            <div className="flex name">
              <div className="flex-initial ">
                <div className="image-contanainer">
                  <img
                    src={user?.image !== null ? user?.image : ProfileImage}
                    alt="Profile"
                    className="w-[142px] pr-image"
                  />
                </div>
              </div>
              <div className="flex-initial infoCcontainer">
                <h3 className="student-name">
                  {user?.first_name ? user?.first_name : user?.username}{" "}
                  {user?.last_name ? user.last_name : ""}{" "}
                </h3>
                {user?.universities?.map((item, index) => (
                   <div className="flex  items-center aboutContainer">
                       <img src={University} alt="Uni"/>
                       <p className="info-about">{item.name}</p>
                   </div>
                ))}

                {user?.degrees.map((item, index) => (
                    <div className="flex items-center" key={index}>
                        <img src={Cap} alt="Uni-cap"/>

                       <p className="info-about">{item.name}</p>
                   </div>
                ))}
                {user?.user_type !== "TEACHER" && (
                  <div className="infoAboutMain mt-4">
                    <p className="info-about text-12" onClick={() => {
                      if(user?.subscription_name === "Basic Subscription" && user?.free_trial === true){
                        setCancelSubscriptionModal(true)
                      }
                    }}>{headingHandle(user)}</p>
                    <div className="FreeTrailBtnSec">
                      {user?.user_type !== "TEACHER" && (
                        <div className="toolTipMain ">
                          <img
                            src={InfoImg}
                            alt="info-img"
                            id="freeIcon"
                            className="mr-1 w-16 md:w-24 custom:w-5 custom:mr-4"
                            onMouseEnter={() => setTrialExpiredInfo(true)}
                            onMouseLeave={() => setTrialExpiredInfo(false)}
                          />
                          {trialExpiredInfo && (
                            <div className=" tooltipSSec fixing">
                              <InfoBox text={handleInfoSubscription(user)} />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}

                <div
                  className="flex items-center editContainer"
                  onClick={() => setIsOpenForEdit(true)}
                >
                  <img src={Pencil} alt="Pen" />
                  <p className="text-primary ml-16 font-bold editText">
                    Edit info
                  </p>
                </div>
                <div
                  className="flex items-center editContainer"
                  onClick={() => setIsOpenForPassword(true)}
                >
                  <img src={EditPassword} alt="Pen" style={{ width: 20 }} />
                  <p className="text-primary ml-16 font-bold editText">
                    Change Password
                  </p>
                </div>
                {user?.user_type !== "STUDENT" && (
                  <>
                    <div
                      className="flex items-center editContainer"
                      onClick={() => history.push({ pathname: "/time-slots" })}
                    >
                      <img src={timeSlot} alt="Pen" style={{ width: 20 }} />
                      <p className="text-primary ml-16 font-bold editText">
                        Add Time Slots
                      </p>
                    </div>

                    <div
                      className="flex items-center editContainer"
                      onClick={() => history.push({ pathname: "/sessions" })}
                    >
                      <img src={session} alt="Pen" style={{ width: 20 }} />
                      <p className="text-primary ml-16 font-bold editText">
                        Sessions
                      </p>
                    </div>

                    <div
                      className="flex items-center editContainer"
                      onClick={() => handleZoom()}
                    >
                      <img src={session} alt="Pen" style={{ width: 20 }} />
                      <p className="text-primary ml-16 font-bold editText">
                        Integrate Zoom Account
                      </p>
                    </div>
                  </>
                )}
                {user?.user_type === "STUDENT" && (
                  <>
                    <div
                      className="flex items-center editContainer"
                      onClick={() =>
                        history.push({ pathname: "/user-sessions" })
                      }
                    >
                      <img src={session} alt="Pen" style={{ width: 20 }} />
                      <p className="text-primary ml-16 font-bold editText">
                        My Tutoring Sessions
                      </p>
                    </div>
                    {paidModules.length > 0 && getApiKey() && (
                      <div
                        className="flex items-center editContainer"
                        onClick={() => history.push({ pathname: "/library" })}
                      >
                        <FaBookOpen className="text-primary" />
                        {/* <img src={session} alt="Pen" style={{ width: 20 }} /> */}
                        <p className="text-primary ml-16 font-bold editText">
                          My Library
                        </p>
                      </div>
                    )}
                  </>
                )}
                {user?.author && user?.author !== null && (
                  <div
                    className="flex items-center editContainer"
                    onClick={() =>
                      history.push({ pathname: "/content-receipts" })
                    }
                  >
                    <img src={session} alt="Pen" style={{ width: 20 }} />
                    <p className="text-primary ml-16 font-bold editText">
                      My Receipts
                    </p>
                  </div>
                )}
                {/* {user.author !== null && user?.author && (
                  <div
                  className="flex items-center editContainer"
                  onClick={() => history.push({ pathname: "/payment-methods" })}
                >
                  <img src={session} alt="Pen" style={{ width: 20 }} />
                  <p className="text-primary ml-16 font-bold editText">
                    Method of Payment
                  </p>
                </div>
                )} */}

                {/* Complete Your Profile Section Starts */}

                {/* {user?.user_type === "STUDENT" && (
                  <div
                    className="flex items-center editContainer"
                  >
                    <div className="infoAboutMain">
                    <p className="info-about flex ml-0 another-class">
                    <img src={completeProfileSvg} alt="Pen" style={{ width: 20 }} />
                    <p className="text-primary ml-16 font-bold editText" onClick={() => history.push({ pathname: "/signup/step-one" })}>
                      Complete your profile
                    </p>
                    </p>
                    <div className="FreeTrailBtnSec new-set">
                      <div className="toolTipMain ">
                        <img
                          src={InfoImg}
                          alt="info-img"
                          id={'complete-icon'}
                          className=" w-16 md:w-24 custom:w-5 custom:mr-4"
                          onMouseEnter={() => setCompleteProfile(true)}
                          onMouseLeave={() => setCompleteProfile(false)}
                        />
                        {completeProfile && (
                          <div className=" tooltipSSec new-width">
                            <InfoBox
                              text={
                                "Please Complete your profile to help us personalise your account. We will recommend you study materials and tutors based on what you study"
                              }
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  </div>
                )} */}

                {/* Complete your profile section ends */}
              </div>
            </div>
          </div>
          <div className="flex-1  image-container">
            <div className="pb-12 custom2:pb-56 mt-14 custom2:flex xl:justify-start custom2:pl-28 lg:pl-28 xl:px-40 relative">
              <div
                className="leafWrap custom2:block absolute custom2:-left-9 custom2:top-6 custom2:w-40 xl:left-1 xl:w-full  z-10"
                style={{ zIndex: 1 }}
              >
                <LazyImage
                  src={
                    "https://simplestudying.com/static/media/leafs%20(2).3e024d8c.webp"
                  }
                  alt="Leafs"
                />
              </div>
              <div
                className="w-full  h-40 custom2:h-80 sm:h-80 xl:h-80 linear-background relative z-20 xl:mt-1"
                style={{ borderRadius: "25px", zIndex: 3 }}
              >
                <div
                  className="w-full h-40 sm:h-80 xl:h-80  custom2:h-80 absolute right-2 top-2 md:right-5 md:top-5 overflow-hidden"
                  style={{ borderRadius: "25px", zIndex: 1 }}
                >
                  <VideoPlayer
                    controls={true}
                    url={userCurrency === 'UK' && userIsLaw === true ? "https://youtu.be/sIy7mQHG2Zs" : "https://www.youtube.com/shorts/_nN2jXORhVQ?feature=share"}
                  />
                </div>
              </div>
            </div>
            {/* <p
              style={{
                fontSize: "24px",
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              Premium Subscription Includes
            </p>
            {bulletDetail?.length > 0
              ? bulletDetail?.map((item, index) => {
                  return (
                    <div className="tickBulletSec3 ">
                      <FaCheckCircle />
                      <h5>{item.heading}</h5>
                      <div className="toolTipMain tickBulletSec-infoicon ">
                        <img
                          id={index}
                          src={InfoImg}
                          alt="info-img"
                          className="mr-1 w-16 md:w-24 custom:w-5 custom:mr-4"
                          onMouseEnter={() => {
                            setShowInfo(index);
                            mouseEnter(index);
                          }}
                          onMouseLeave={() => {
                            setShowInfo(null);
                            mouseLeave(index);
                          }}
                        />
                        {showInfo === index && (
                          <div className=" tooltipSSec Premium-tip">
                            <InfoBox text={item?.info} />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })
              : null} */}
            {/* <div className="mt-24 text-primary underline text-24">
              <a
                href="https://simplestudying.com/first-class-students-at-simplestudying/"
                className="text-16"
              >
                See how law students at StudyCrowd.AI get a First Class in
                their exams
              </a>
            </div> */}
          </div>
        </div>
      </div>

      <Modal
          open={cancelSubscriptionModal}
          onClose={() => setCancelSubscriptionModal(false)}
          focusTrapped={false}
          center
          classNames={{
            overlay: "custom-overlay-class",
            modal: "react-modal-class1",
          }}
        >
          <div className="w-full flex space-x-20">
            <div className="w-[50%]" style={{ width: "50%" }}>
              <div className="w-full">
                <h1 className="text-24 font-bold">
                  StudyCrowd.AI free trial in progress..
                </h1>
                <p>
                  Improve your grades without reading thousands of pages from overcomplicated textbooks.<br/>
                  You will be charges for the study materials you selected at the end of your free trial unless you cancel.
                </p>
              </div>
              <div className="w-full">
                {/* {subscriptionTypes.map((item, index) => {
                  return (
                    <div
                      className="checkbox flex items-center mt-2 md:mt-4"
                      key={index}
                    >
                      <div className="flex justify-between w-full items-center">
                        <div>
                          <input
                            id={item.id}
                            type="radio"
                            name="subscription"
                            className="input-assumpte"
                          />
                          <label
                            htmlFor={item.id}
                            className="text-16 custom2:w-10/12 md:text-body-large flex items-center"
                          >
                            {item.label}
                          </label>
                        </div>
                        <span className="ml-4">{item.price}</span>{" "}
                      </div>
                    </div>
                  );
                })} */}
              </div>
              <div className="flex space-x-28" style={{ marginTop: "50px" }}>
                <p>Due {formattedDate}</p>
                <p>US $144</p>
              </div>
              <div className="flex space-x-48" style={{ marginTop: "10px" }}>
                <p>
                  <b>Due Today</b>
                </p>
                <p>
                  <b>US $0</b>
                </p>
              </div>
              <div
                className="flex justify-center items-center w-full ml-0"
                style={{ marginTop: "50px" }}
              >
                <Button filled text={"Cancel your free trial"} className="w-full" onClick={() => {
                  handleCancelSubscription()
                }}/>
              </div>
            </div>
            <div
              className="w-[50%] bg-lightgray"
              style={{ marginTop: "8%", width: "50%" }}
            >
              <ol className="relative border-l-2 border-red-500 dark:border-red-500 border-dashed space-y-16">
                <li className="mb-10 ms-6 relative">
                  <span className="absolute flex items-center justify-center w-12 h-12 bg-white border border-primary  rounded-full -left-6 ring-24 ring-white dark:ring-white dark:bg-white">
                    <FaCalendar className="text-primary" />
                  </span>
                  <h3 className="flex items-center mb-1 font-bold text-lg font-semibold ml-12 text-primary dark:text-primary">
                    Today
                  </h3>
                  <p className="block mb-2 ml-12 text-xs font-normal leading-none text-red dark:text-gray-500">
                    Your 3 days free trial start now
                  </p>
                </li>
                <li className="mb-10 ms-6 relative">
                  <span className="absolute flex items-center justify-center w-12 h-12 bg-white border border-primary rounded-full -left-6 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                    <FaBell className="text-primary" />
                  </span>
                  <h3 className="mb-1 text-lg text-primary font-bold ml-12 font-semibold text-gray-900 dark:text-white">
                    {formattedDate}
                  </h3>
                  <p className="block mb-2 ml-12 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                    Your subscription starts, unless you've cancelled during the
                    trial
                  </p>
                </li>
                <li className="ms-6 relative">
                  <span className="absolute flex items-center justify-center w-12 h-12 bg-white border border-primary rounded-full -left-6 ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900">
                    <FaCreditCard className="text-primary" />
                  </span>
                  <h3 className="mb-1 text-lg text-white font-bold ml-12 font-semibold text-gray-900 dark:text-white">
                    October 3rd, 2024
                  </h3>
                  <p className="block mb-2 ml-12 text-sm font-normal leading-none text-white dark:text-white">
                    Your subscription starts, unless you've cancelled during the
                    trial
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </Modal>

      {/* {user?.user_type === "STUDENT" && (
        <div className="recommendations-and-cards bg-white p-2 sm:p-4 lg:px-20 lg:pt-6 xl:px-56 xl:pt-14">
          <div className="w-full neither-container">
            <p className="note">
              Neither our company, nor our products are officially affiliated{" "}
              <br className="break" />
              with any of the universities listed{" "}
            </p>
          </div>
          <div className="mb-50 recom-container">
            <h2 className="text-h4 sm-text-h3 md:text-h2 font-bold text-darkest recom">
              Recommendations
            </h2>
          </div>
          <div className="flex gap:12 lg:gap-30 cards-container flipMain">
            {recommendation.slice(0, 4).map((item, index) => {
              return (
                <div class="flip-card" onClick={() => slugName(item)}>
                  <div class="flip-card-inner">
                    <div class="flip-card-front">
                      <StudyMaterials
                        key={index}
                        image={item?.item?.image}
                        cardTitle={item?.label}
                        slug={item}
                        text={item?.item?.short_description}
                        handleClick={slugName}
                        // price={`$ ${item?.item?.price ? item.item?.price : ' 99.99'}`}
                      />
                    </div>
                    <div class="flip-card-back p-4">
                      <p
                        className="font-bold text-lg cursor-pointer"
                        style={{ fontSize: "22px" }}
                      >
                        {item.label}
                      </p>
                      <div className={`backSubBox flex my-5 flex-wrap gap-3`}>
                        {item?.value?.notes !== 0 ? (
                          <p
                            className="py-1 px-2 text-greenGradient font-normal text-08 border-2 border-green-500"
                            style={{ borderRadius: "43px" }}
                          >
                            Revision Notes
                          </p>
                        ) : null}
                        {item?.value?.essays !== 0 ? (
                          <p
                            className="p-1 text-greenGradient font-normal text-08 border-2 border-green-500"
                            style={{ borderRadius: "43px" }}
                          >
                            Model Essays
                          </p>
                        ) : null}
                        {item?.value?.summaries !== 0 ? (
                          <p
                            className="p-1 text-greenGradient font-normal text-08 border-2 border-green-500"
                            style={{ borderRadius: "43px" }}
                          >
                            Case Summaries
                          </p>
                        ) : null}
                        {item?.value?.quiz !== 0 ? (
                          <p
                            className="p-1 text-greenGradient font-normal text-08 border-2 border-green-500"
                            style={{ borderRadius: "43px" }}
                          >
                            Quiz
                          </p>
                        ) : null}
                        {item?.value?.flash_cards !== 0 ? (
                          <p
                            className="p-1 text-greenGradient font-normal text-08 border-2 border-green-500"
                            style={{ borderRadius: "43px" }}
                          >
                            Flash Cards
                          </p>
                        ) : null}
                        {item.tutorial_videos !== 0 ? (
                          <p
                            className="p-1 text-greenGradient font-normal text-08 border-2 border-green-500"
                            style={{ borderRadius: "43px" }}
                          >
                            Tutorial Videos
                          </p>
                        ) : null}
                      </div>
                      <div
                        className="contentColor"
                        dangerouslySetInnerHTML={{
                          __html: item?.value?.short_description,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div
            className="mt-50 mb-50 bg-white w-full sm:w-3/4 md:w-2/4 text-center"
            style={{ margin: "10px auto 30px" }}
          >
            <Button className="exploreBtn" onClick={() => history.push("/")}>
              Explore more study materials
            </Button>
          </div>
        </div>
      )} */}

      {/* new cards */}

      {user?.user_type === "STUDENT" && (
        <div className=" w-80% m-auto">
          {userIsLaw === true && userCurrency === "UK" ? (
            <p
              className=" fw-semibold  text-28   md:text-h3 xl:text-36
   w-full sm-md:w-60% text-center m-auto mt-5 mb-7"
            >
              To obtain a <span className="text-yellowish"> First-Class</span>{" "}
              law degree, follow these
            </p>
          ) : (
            <p
              className=" fw-semibold  text-28 md:text-h3 xl:text-36
   w-full sm-md:w-60% m-auto mt-5 mb-7"
            >
              Recommendations
            </p>
          )}

          <div className="flex flex-col sm-md:flex-row flex-wrap md:flex-nowrap gap-6">
            {/* 1 */}
            <div
              className="bg-red rounded-md flex flex-1 flex-col justify-center p-6 items-center pb-16   relative"
              style={{
                boxShadow: "4px 8px 20px rgba(2, 23, 54, 0.05)",
                flex: "35%",
                flexBasis: "35%",
              }}
            >
              <p className="text-yellowish text-h3 fw-semibold my-3 ">Step 1</p>
              <div className=" my-9">
                <img src={step1Img} />
              </div>
              {userIsLaw === true && userCurrency === "UK" ? (
                <>
                  <p className="text-h3 fw-semibold text-center my-5 leading-7">
                    Study using{" "}
                    <span className="text-yellowish">our materials</span>
                  </p>
                  <ul className="list-disc p-3">
                    <li>
                      Consistent daily study on our platform with a premium
                      subscription should lead to attaining a First-Class law
                      degree.
                    </li>
                    <li>
                      Our study materials were created by First-Class law
                      graduates.
                    </li>

                    <li>
                      Our notes, example essays, flashcards, quizzes, tutorials
                      and videos are all specifically designed to help you
                      achieve a First-Class in the simplest manner possible.
                    </li>
                    <li>
                      Our materials can{" "}
                      <span className="text-yellowish">
                        save you almost 800 hours per year in reading
                      </span>{" "}
                      compared to textbooks.
                    </li>
                  </ul>
                </>
              ) : (
                <>
                  <p className="text-h3 fw-semibold text-center my-5 leading-7">
                    Study using{" "}
                    <span className="text-yellowish">our materials</span>
                  </p>
                  <p>
                    We offer simplified, condensed study materials created by
                    top students and graduates to save you thousands of hours of
                    studying while maximizing your chances of achieving high
                    grades
                  </p>
                </>
              )}

              <div className="w-90% h-fit my-3 absolute bottom-0 m-auto mx-5">
                <Button
                  className="confrmBtn"
                  filled
                  onClick={() => handleRoute("studyMaterial")}
                >
                  Continue
                </Button>
              </div>
            </div>
            {/* 2 */}

            <div
              className="bg-white p-6 rounded-2xl flex flex-1 flex-col pb-16 items-center   relative"
              style={{
                boxShadow: "4px 8px 20px rgba(2, 23, 54, 0.05)",
                flex: "35%",
                flexBasis: "35%",
              }}
            >
              <p className="text-yellowish text-h3 fw-semibold text-center my-3">
                Step 2
              </p>
              <div className=" my-9">
                <img
                  src={
                    userIsLaw === true && userCurrency === "UK"
                      ? step2Img
                      : step3Img
                  }
                  className="m-auto"
                />
              </div>
              {userIsLaw === true && userCurrency === "UK" ? (
                <>
                  <p className="text-h3 fw-semibold text-center my-5 leading-7">
                    Receive personalised{" "}
                    <span className="text-yellowish">one-on-one</span> tutoring
                    every month{" "}
                  </p>
                  <ul className="list-disc p-3">
                    <li>
                      90% of our students who received{" "}
                      <span className="text-yellowish">
                        6 hours of tutoring
                      </span>{" "}
                      in addition to regular study with our premium subscription
                      achieved a First-Class.{" "}
                    </li>
                  </ul>
                  <div className="w-90% h-fit  my-3 absolute bottom-0 m-auto ">
                    <Button
                      className="confrmBtn"
                      filled
                      onClick={() => handleRoute("tutoring")}
                    >
                      Continue
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <p className="text-h3 fw-semibold text-center my-5 leading-7">
                    Become a Content Creator
                  </p>
                  <p>
                    If you've received high grades in any of your subjects, you
                    can upload your study notes for sale, It's an easy way to
                    earn money and supplement your income
                  </p>
                  <div className="w-90% h-fit  my-3 absolute bottom-0 m-auto ">
                    <Button
                      className="confrmBtn"
                      filled
                      onClick={() => handleRoute("content")}
                    >
                      SELL YOUR STUDY NOTES FOR CASH NOW
                    </Button>
                  </div>
                </>
              )}
            </div>

            {/* 3 */}
            {userIsLaw === true && userCurrency === "UK" && (
              <div
                className="bg-white p-6 rounded-2xl flex flex-1 flex-col  items-center pb-16 relative"
                style={{
                  boxShadow: "4px 8px 20px rgba(2, 23, 54, 0.05)",
                  flex: "35%",
                  flexBasis: "35%",
                }}
              >
                <p className="text-yellowish text-h3 fw-semibold text-center my-3">
                  Step 3
                </p>
                <div className=" my-9">
                  <img src={step3Img} className="m-auto" />
                </div>
                <p className="text-h3 fw-semibold text-center my-5 leading-7">
                  Attend the free{" "}
                  <span className="text-yellowish">revision sessions</span>
                </p>
                <ul className="list-disc p-3">
                  <li>
                    We conduct free revision sessions closer to the exam period,
                    which you should attend. This is an opportunity to{" "}
                    <span className="text-yellowish">
                      revise and ask questions
                    </span>{" "}
                    to the tutor
                  </li>
                </ul>

                <div className="w-90% h-fit my-3 absolute bottom-0  m-auto ">
                  <Button
                    className="confrmBtn"
                    filled
                    onClick={() => handleRoute("tutoring")}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            )}
          </div>
          <div className="mt-48" id="reviewsio-carousel-widget"></div>
        </div>
      )}

      {/* end of new cards */}
      <div className="upgrade-and-video-mobile">
        <div className="flex justify-end">
          <p className="underline text-primary font-bold text-h5 mb-30 md:text-body-large upgrade">
            Upgrade Membership
          </p>
        </div>
        <div className="flex-1 image-container">
          <img src={WelcomeVideo} alt="Video" />
        </div>
      </div>
      <ResetUserPasswordModal
        open={isOpenForPassword}
        setOpen={setIsOpenForPassword}
      />
      <EditUserInfoModal
        open={isOpenForEdit}
        setOpen={setIsOpenForEdit}
        details={user}
      />
    </div>
  );
};
const mapStateToProps = (state) => ({
  user_detail: userData(state),
  studyModulesData: studyModulesData(state),
  dataCatalogue: catalogueData(state),
});
const mapDispatchToProps = (dispatch) => ({
  // getUserCSRF: () => dispatch(getUserCSRF()),
  getUserInfo: () => dispatch(getUserInfo()),
  getSpecificStudyModules: () => dispatch(getSpecificStudyModules()),
  getCatalogue: (id) => dispatch(getCatalogue(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
