import React, { useEffect, useState } from "react";
import Button from "../../components/EmptyButton";
import SubsImage1 from "../../assets/images/subs-image-1.webp";
import checkmark from "../../assets/images/Checkmark.svg";
import price from "../../assets/images/price.png";

import { useHistory, withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect, useDispatch } from "react-redux";
import { cancelSubscriptionData, subscriptionData } from "../../redux/selectors";
import { cancelSubscriptionAction, createErrorAlert, getSubscriptions } from "../../redux/actions";
import FullLoader from "../../components/Loader/FullLoader";
import { getUser } from "../../helpers";

const Step3Content = (props) => {
  const { handleNext, cancelSubscription, cancelSubscriptionData, getSubscriptions, data } = props

  const history = useHistory()
  const dispatch = useDispatch()

  const [state, setState] = useState({
    lowCost: [],
    premium: [],
    loader: false,
    user: JSON.parse(getUser())
  })

  useEffect(() => {
    getSubscriptions()
  },[])

  useEffect(() => {

    if(data){
      let remaining = []
        for(let i=0; i<data.length; i++){
          if(data[i].name.includes('STARTER')){
            state.lowCost.push(data[i])
            state.lowCost.reverse()
          } else{
            remaining.push(data[i])
            let sliced = remaining.slice(3)
            setState((prevState) => ({
              ...prevState,
              premium: sliced
            }))
          }
        }
    }

  },[data])


  const headingHandlePremium = (subscription) => {
    if(subscription !== null) {
      if(subscription === 'QUICK BOOST OF YOUR GRADES'){
        return 'PREMIUM ALL INCLUSIVE SUBSCRIPTION WITH MONTHLY BILLING'
      } else if(subscription === 'GET COVERED FOR THE EXAM PERIOD'){
        return 'PREMIUM ALL INCLUSIVE SUBSCRIPTION WITH QUARTERLY BILLING'
      } else if(subscription === 'IDEAL IF YOU AIM FOR A FIRST CLASS'){
        return 'PREMIUM ALL INCLUSIVE SUBSCRIPTION WITH ANNUAL BILLING'
      } else if(subscription === 'STARTER PACK FOR IMPROVING YOUR GRADES (MONTHLY BILLED)'){
        return 'BASIC STARTER PACK SUBSCRIPTION WITH MONTHLY BILLING'
      } else if(subscription === 'STARTER PACK FOR IMPROVING YOUR GRADES (QUARTERLY BILLED)'){
        return 'BASIC STARTER PACK SUBSCRIPTION WITH QUARTERLY BILLING'
      } else if(subscription === 'STARTER PACK FOR IMPROVING YOUR GRADES (ANNUALLY BILLED)'){
        return 'BASIC STARTER PACK SUBSCRIPTION WITH ANNUAL BILLING'
      }
    }
  }


  const handleChangeSubscription = () => {
    history.push("/subscription");
  }

  const handleSubCancel = (subscription) => {
    setState((prev) => ({
      ...prev,
      loading: true
    }))
    cancelSubscription(subscription).then((res) => {
      setState((prev) => ({
        ...prev,
        loading: false
      }))
        if(res.status === 200){
            localStorage.setItem('cancelSubscription', true)
            setState((prev) => ({
              ...prev,
              user: prev.sub_status = true
            }))
            localStorage.setItem("app_user", JSON.stringify(state.user));
            window.location.reload()
            history.push('/')
        } else{
            dispatch(createErrorAlert(400, '', 'Something went wrong', 400))
        }
    })
  }

  return (
    <div>
      {state.loader ? (<FullLoader/>) : null}
      <p>
        {state.user?.first_name}, we're sorry to see you go. Your membership has now been cancelled, but you'll still have access to the study materials until the end of your free trial, with no charges after that. 
      </p>
      <br/>
      <p>You're always welcome to return to StudyCrowd.AI and enroll in study modules recommended just for you.</p>
      {state.lowCost.length > 0 && !state.user?.subscription_name?.includes('STARTER') && <div className="flex flex-col md:flex-row justify-center gap-1 pt-10">
        
        {/* <div  className="m-auto" style={{width:"200px" ,height:'200px'}}>
          <img src={SubsImage1} />
        </div>
        <div>
          <h3 className="text-20 font-bold ">{headingHandlePremium(state.lowCost[0]?.name)}</h3>
          <ul className="pt-4 step3-ui">
            <li className="text-14 pb-2 flex"><img src={checkmark} className=" mr-1"/>Unlimited Case Summaries</li>
            <li className="text-14 pb-2 flex max-w-fit"><img src={checkmark} className=" mr-1"/>Short Summaries of Key Legal Principles</li>
            <li className="text-14 pb-2 flex"><img src={checkmark} className=" mr-1"/>Cancel Anytime</li>
          </ul>
          <span className="priceText text-42 font-bold xl:text-h1 linear-color relative " style={{marginLeft:'20px'}}>
              {state.lowCost[0].membership_price}
              <span
                className="
                        absolute 
                        -inset-y-2
                        left-14
                        sm:left-20
                        text-large-medium 
                        font-bold 
                        linear-color
                        xl:text-36 curncyIcon priceText currency-sign"
              >
                £
              </span>
              </span>
        </div> */}
        <div className="flex flex-col justify-center sm-md:justify-start  px-1 mt-5 sm-md:mt-0">
                    <div className="flex flex-col sm-md:flex-row justify-between px-26 pb-6 mt-10 sm-md:space-x-10 md:space-x-24">

          <Button
            onClick={() => handleSubCancel(state?.user?.subscription_name)}
            filled
          >
            Check out study materials recommended for you
          </Button>
          </div>
        </div>
      </div>}
    </div>
  );
};
const mapStateToProps = (state) => ({
    cancelSubscriptionData: cancelSubscriptionData(state),
    data: subscriptionData(state),
  });
  
  const mapDispatchToProps = (dispatch) => ({
    cancelSubscription: (sub_name) => dispatch(cancelSubscriptionAction(sub_name)),
    getSubscriptions: () => dispatch(getSubscriptions()),
  });
  
  export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
  )(Step3Content);