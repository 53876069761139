import React, {useState} from 'react';

import Container from '../../components/container';
import Button from '../../components/EmptyButton';
import Input from '../../components/Input/InputForm';
import Select from '../../components/Select';
import ShoppingSucessModal from '../../components/ShoppingSucessModal';
import StripeCheckout from 'react-stripe-checkout';
import {Helmet} from "react-helmet";

const CheckoutPage = () => {
    
    const [cardName, setCardName] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [year, setYear] = useState('');
    const [securityCode, setSecurityCode] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [shoppingItems, setShoppingItems] = useState([
        {
            id: 1,
            title: 'Contract Law',
            university: 'University of Oxford',
            classNumber: '1st Class',
            price: '99.99',
        },
        {
            id: 2,
            title: 'Criminal Law',
            university: 'StudyCrowd.AI',
            price: '99.99',
        },
        {
            id: 3,
            title: 'Employment Law',
            university: 'University of Oxford',
            classNumber: '2.1 Class',
            price: '99.99',
        },
        {
            id: 4,
            title: 'Contract Law',
            university: 'University of Oxford',
            classNumber: '1st Class',
            price: '99.99',
        },
        {
            id: 5,
            title: 'Criminal Law',
            university: 'StudyCrowd.AI',
            price: '99.99',
        },
        {
            id: 6,
            title: 'Employment Law',
            university: 'University of Oxford',
            classNumber: '2.1 Class',
            price: '99.99',
        },
    ]);
    const onToken = (token) => {
    };
    return (
        <div className="custom2:pb-20 pt-6 bg-creator-group bg-white">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Simple Studying | Checkout</title>
                <meta name="description" content="Checkout Description"/>
                <link rel="canonical" href="https://simplestudying.com/checkout"/>
                <meta name="robots" content="index, follow"/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:locale:alternate" content="en_gb"/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Simple Studying | Profile"/>
                <meta property="og:description" content="Profile Description"/>
                <meta property="og:url" content="https://simplestudying.com/checkout"/>
                <meta property="og:site_name" content="Simple Studying"/>
            </Helmet>
            <ShoppingSucessModal
                isOpen={modalOpen}
                closeModal={() => setModalOpen(false)}
                onClickSecondButton={() => setModalOpen(false)}
                title="Thank you for your order!"
                buttonTwoText="View your study materials on your library"
            />
            <Container>
                <div className="pt-10 lg:pt-20 pb-12">
                    <p className="text-h3 md:text-36 font-bold text-darkest">Checkout</p>
                </div>
                <div className="flex flex-col lg:flex-row lg:space-x-7">
                    <div className="mb-10 w-full lg:w-2/3">
                        <div className="md:w-472 pb-12">
                            <label className="text-darkest text-body-large mb-8">
                                Choose your country
                            </label>
                            <Select/>
                        </div>
                        <div className="bg-white p-12"
                             style={{boxShadow: '4px 8px 20px rgba(2, 23, 54, 0.03)', borderRadius: '25px'}}>
                            <div className="flex flex-col text-darkest">
                                <div className="w-full mb-9">
                                    <label className="mb-8">
                                        Name on card
                                    </label>
                                    <Input placeholder="" value={cardName} setValue={setCardName} autoComplete="off"/>
                                </div>
                                <div className="w-full mb-9">
                                    <label className="mb-8">
                                        Card number
                                    </label>
                                    <Input placeholder="" value={cardNumber} setValue={setCardNumber} autoComplete="off"
                                           type="number"/>
                                </div>
                                
                                
                                <div className="flex flex-col md:flex-row md:space-x-6 mb-9 ">
                                    <div className="w-full mb-6 md:mb-0 md:w-2/4">
                                        <label className="mb-8">
                                            MM/YY
                                        </label>
                                        <Input placeholder="" value={year} setValue={setYear} autoComplete="off"
                                               type="text"/>
                                    </div>
                                    
                                    <div className="w-full md:w-2/4">
                                        <label className="mb-8">
                                            Security Code
                                        </label>
                                        <Input placeholder="" value={securityCode} setValue={setSecurityCode}
                                               type="password" autoComplete="off"/>
                                    </div>
                                </div>
                            </div>
                            <div className="checkbox flex items-center ">
                                <input type="checkbox" className="input-assumpte" id="input-confidencial"
                                       checked={true}/>
                                <label for="input-confidencial"
                                       className="text-16 custom2:w-10/12 md:text-body-large flex items-center">Remember
                                    this card</label>
                            </div>
                        </div>
                    </div>
                    <div className="p-12 mb-10 bg-white w-full lg:w-1/3 h-628"
                         style={{borderRadius: '14px', boxShadow: ' 4px 8px 20px rgba(2, 23, 54, 0.03)'}}>
                        <div>
                            <p className="text-h3 text-darkest  font-bold ">Order Summary</p>
                        </div>
                        <div className="pb-11">
                            {shoppingItems.map(elem => (
                                <div
                                    className="flex flex-row justify-between items-center text-darkGrey text-13 mac:text-15 mt-5">
                                    <div className="flex justify-start w-2/4 ">
                                        <p>{elem.title}</p>
                                    </div>
                                    <div className="flex justify-end w-2/4">
                                        <p>
                                            £ {elem.price}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="flex flex-row justify-between items-center text-darkest text-15 mb-5">
                            <div className="flex justify-start w-2/4 ">
                                <p>Subtotal:</p>
                            </div>
                            <div className="flex justify-end w-2/4">
                                <p>
                                    £ 599.94
                                </p>
                            </div>
                        </div>
                        <div
                            className="flex flex-row justify-between items-center text-darkest text-body-large font-bold mb-11"
                            style={{paddingTop: '18px', borderTop: '2px solid #EEEEEE'}}>
                            <div className="flex justify-start w-2/4 ">
                                
                                <p>Total price:</p>
                            </div>
                            <div>
                                <p>
                                    £ 599.94
                                </p>
                            </div>
                        </div>
                        
                        <div className="flex flex-col">
                            <div className="mb-10 w-full">
                                <StripeCheckout
                                    name="StudyCrowd.AI"
                                    description="For your educational purposes"
                                    image="https://www.vidhub.co/assets/logos/vidhub-icon-2e5c629f64ced5598a56387d4e3d0c7c.png" // the pop-in header image (default none)
                                    ComponentClass="div"
                                    panelLabel="Give Money"
                                    amount={1000000}
                                    currency="USD"
                                    stripeKey="..."
                                    locale="zh"
                                    email="info@simplestudying.com"
                                    shippingAddress
                                    billingAddress={false}
                                    allowRememberMe
                                    token={onToken}
                                
                                >
                                    {/*<Button filled onClick={() => setModalOpen(true)}>*/}
                                    {/*    <p className="text-center text-small-text xl:text-body-large">Complete*/}
                                    {/*        payment</p>*/}
                                    {/*</Button>*/}
                                </StripeCheckout>
                            <Button filled onClick={() => setModalOpen(true)}>
                                        <p className="text-center text-small-text xl:text-body-large">Complete
                                            payment</p>
                                    </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        
        </div>
    );
};

export default CheckoutPage;
